export default {
  props: {
    border: {
      type: String,
      default: '0',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
