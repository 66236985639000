import { render, staticRenderFns } from "./RecoverPasswordView.html?vue&type=template&id=6c36c49e&scoped=true&"
import script from "./RecoverPasswordView.js?vue&type=script&lang=js&"
export * from "./RecoverPasswordView.js?vue&type=script&lang=js&"
import style0 from "./RecoverPasswordView.scss?vue&type=style&index=0&id=6c36c49e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c36c49e",
  null
  
)

export default component.exports