import { mapState } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { SearchAPI as SearchApi, DuplicatedTickets } from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import { INTEGRATION_OPTIONS, INTEGRATION_TYPES } from '@/config'
import { Form } from '@/shared/mixins'

export default {
  mixins: [Form],
  components: {
    SearchApi,
    DuplicatedTickets
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
      default: ''
    },
    selected: {
      type: Object,
      default: undefined
    }
  },
  computed: mapState({
    agreements(state) {
      return state.clients.agreements.results.map(a => ({
        id: a.code,
        text: a.code ? a.code : this.$t('anyone')
      }))
    },
    integrationNif() {
      return (this.integration === INTEGRATION_TYPES.T_CON || this.integration === INTEGRATION_TYPES.T_IES)
    },
    integrationEmail() {
      return (this.integration === INTEGRATION_TYPES.T_EMAIL || this.integration === INTEGRATION_TYPES.T_TAAF)
    }
  }),
  validations: {
    email: { required: requiredIf('integrationEmail') },
    agreement: { required },
    integration: { required },
    nif: { required: requiredIf('integrationNif') },
  },
  data() {
    return {
      email: '',
      query: {},
      integration: '',
      agreement: '',
      nif: '',
      INTEGRATION_OPTIONS,
      INTEGRATION_TYPES
    }
  },
  methods: {
    postTicket() {
      let data = {}
      if (this.integration === INTEGRATION_TYPES.T_CON || this.integration === INTEGRATION_TYPES.T_IES) {
        data = {
          integration: this.integration,
          agreement: this.agreement,
          nif: this.nif
        }
      } else if (this.integration === INTEGRATION_TYPES.T_EMAIL || this.integration === INTEGRATION_TYPES.T_TAAF) {
        data = {
          email: this.email,
          integration: this.integration,
          agreement: this.agreement,
        }
      } else {
        data = {
        }
      }
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.requestInvoicesIntegration(data, this.type, user)
      this.$emit('send', ticket)
      this.reset()
    },
    reset() {
      this.email = ''
      this.integration = ''
      this.agreement = ''
      this.nif = ''
      this.$v.$reset()
    },
    updateQuery() {
      this.query = { invoice_id: this.invoice }
    }
  }
}
