import { PATH_RECOVER_PASSWORD_CONFIRM, STORE_SET_VERSION, PATH_LOGIN } from '@/auth/authConstants'

import watium from '@/../package.json'

export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    v() { return watium.version }
  },
  beforeCreate() {
    this.$http.verifyToken().then((tokenIsValid) => {
      if (!tokenIsValid && this.$route.path !== PATH_RECOVER_PASSWORD_CONFIRM && this.$route.path !== PATH_LOGIN) {
        this.$router.push({ name: 'LoginView', query: { next: this.$route.path } }, () => {})
      }
    }, () => {
      this.$router.push({ name: 'LoginView', query: { next: this.$route.path } }, () => {})
    })
  },
  mounted() {
    document.addEventListener('sw-update', this.markUpdated)
    document.addEventListener('sw-offline', this.offline)
  },
  beforeDestroy() {
    document.removeEventListener('sw-update', this.markUpdated)
    document.removeEventListener('sw-offline', this.offline)
  },
  methods: {
    markUpdated() {
      if (this.$store.state.auth.version !== watium.version) {
        this.$store.dispatch(STORE_SET_VERSION, watium.version)
        document.location.reload(true)
      }
    },
    offline() {
      this.$router.push({ name: 'OFFLINE' }, () => {})
    }
  }
}
