import Form from '@/shared/mixins/Form'

export default {
  name: 'BankAccountInput',
  mixins: [Form],
  props: ['value'],
  data() {
    return {
      iban: '',
      entity: '',
      office: '',
      dc: '',
      account: '',
      error: false,
    }
  },
  watch: {
    iban() {
      this.emitFullAccount()
    },
    entity() {
      this.emitFullAccount()
    },
    office() {
      this.emitFullAccount()
    },
    dc() {
      this.emitFullAccount()
    },
    account() {
      this.emitFullAccount()
    }
  },
  mounted() {
    const ibanInput = this.$el.querySelector('[name="iban"]')
    const entityInput = this.$el.querySelector('[name="entity"]')
    const officeInput = this.$el.querySelector('[name="office"]')
    const dcInput = this.$el.querySelector('[name="dc"]')
    const accountInput = this.$el.querySelector('[name="account"]')
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    ibanInput.addEventListener('paste', (ev) => {
      const pastedValue = ev.clipboardData.getData('text').replace(/\s/g, '').replace(/-/g, '')
      this.iban = pastedValue.substring(0, 4)
      this.entity = pastedValue.substring(4, 8)
      this.office = pastedValue.substring(8, 12)
      this.dc = pastedValue.substring(12, 14)
      this.account = pastedValue.substring(14, 24)
    })
    ibanInput.addEventListener('keyup', (ev) => {
      if (validKeys.indexOf(ev.key) >= 0 && ev.target.value.length === 4) {
        entityInput.focus()
      }
    })
    entityInput.addEventListener('keyup', (ev) => {
      if (validKeys.indexOf(ev.key) >= 0 && ev.target.value.length === 4) {
        officeInput.focus()
      }
    })
    officeInput.addEventListener('keyup', (ev) => {
      if (validKeys.indexOf(ev.key) >= 0 && ev.target.value.length === 4) {
        dcInput.focus()
      }
    })
    dcInput.addEventListener('keyup', (ev) => {
      if (validKeys.indexOf(ev.key) >= 0 && ev.target.value.length === 2) {
        accountInput.focus()
      }
    })
  },
  methods: {
    getFullAccount() {
      return `${this.iban}${this.entity}${this.office}${this.dc}${this.account}`
    },
    emitFullAccount() {
      const fullAccount = this.getFullAccount()
      this.$emit('input', fullAccount)
    }
  }
};
