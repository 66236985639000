import {
  STORE_LOGIN,
  STORE_LOGOUT,
  STORE_SET_PERMISSIONS,
  STORE_SET_NOTIFY,
  STORE_SET_NAME,
  STORE_SET_VERSION,
  TOGGLE_FEEDBACK
} from '@/auth/authConstants'

import { STORE_CLEAR_AGREEMENTS } from '@/clients/clientsConstants'

/* eslint no-param-reassign: [
  "error",
  {
    "props": true,
    "ignorePropertyModificationsFor": ["state"]
  }
] */

export default {
  state: {
    auth: {
      user: {
        user_id: undefined,
        username: undefined,
        exp: undefined,
        email: undefined,
        orig_iat: undefined,
        first_name: undefined,
        last_name: undefined
      },
      token: undefined,
      version: '',
      profile: {},
      permissions: [],
      feedback: false
    }
  },
  mutations: {
    [STORE_SET_NAME](state, payload) {
      state.auth.user.first_name = payload.first_name
      state.auth.user.last_name = payload.last_name
    },
    [STORE_SET_NOTIFY](state, payload) {
      state.auth.profile = payload
    },
    [STORE_LOGIN](state, payload) {
      state.auth.user = payload.user
      state.auth.token = payload.token
      state.auth.profile = payload.profile
    },
    [STORE_LOGOUT](state) {
      state.auth.user = undefined
      state.auth.token = undefined
    },
    [STORE_SET_PERMISSIONS](state, payload) {
      state.permissions = payload
    },
    [STORE_SET_VERSION](state, payload) {
      state.auth.version = payload
    },
    [TOGGLE_FEEDBACK](state) {
      state.auth.feedback = !state.auth.feedback
    }
  },
  actions: {
    [STORE_SET_NAME]({ commit }, payload) {
      commit(STORE_SET_NAME, payload)
    },
    [STORE_LOGIN]({ commit }, payload) {
      commit(STORE_LOGIN, payload)
    },
    [STORE_LOGOUT]({ commit }) {
      commit(STORE_LOGOUT)
      commit(STORE_CLEAR_AGREEMENTS)
    },
    [STORE_SET_PERMISSIONS]({ commit }, payload) {
      commit(STORE_SET_PERMISSIONS, payload)
    },
    [STORE_SET_NOTIFY]({ commit }, payload) {
      commit(STORE_SET_NOTIFY, payload)
    },
    [STORE_SET_VERSION]({ commit }, payload) {
      commit(STORE_SET_VERSION, payload)
    },
    [TOGGLE_FEEDBACK]({ commit }) {
      commit(TOGGLE_FEEDBACK)
    }
  }
}
