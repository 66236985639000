import { UIStatus } from '@/shared/mixins'
import { isUserStaff } from '@/utils'
import { STORE_SET_EMAIL_FILTER } from '@/clients/clientsConstants'
import { STORE_SET_NOTIFY, STORE_SET_NAME } from '@/auth/authConstants'
import {
  SectionHeader, Notification, Card, Checkbox, HelpBox
} from '@/shared/components'

export default {
  components: {
    SectionHeader,
    Card,
    Notification,
    Checkbox,
    HelpBox
  },
  mixins: [UIStatus],
  data() {
    return {
      globalFilters: {
        email: this.$store.state.clients.globalFilters.email
      },
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      first_name: this.$store.state.auth.user ? this.$store.state.auth.user.first_name : '',
      last_name: this.$store.state.auth.user ? this.$store.state.auth.user.last_name : '',
      showWatchAs: false,
      formError: '',
      formSuccess: '',
      updateNotifyMessage: '',
      notify: false
    }
  },
  computed: {
    isStaff() { return isUserStaff() },
    full_name() {
      if (this.$store.state.clients.globalFilters.email) {
        return this.$store.state.clients.globalFilters.email
      }
      if (this.$store.state.auth.user) {
        const firstName = this.$store.state.auth.user.first_name || ''
        const lastName = this.$store.state.auth.user.last_name || ''
        const username = this.$store.state.auth.user.username || ''
        return firstName ? `${firstName} ${lastName}` : username
      }
      return ''
    },
    showErrorMessage() {
      return !!this.formError
    },
    showSuccessMessage() {
      return !!this.formSuccess
    },
    showUpdateNotifyMessage() {
      return !!this.updateNotifyMessage
    }
  },
  watch: {
    updateNotifyMessage() {
      if (this.updateNotifyMessage) {
        setTimeout(() => { this.updateNotifyMessage = '' }, 5000)
      }
    }
  },
  mounted() {
    this.notify = this.$store.state.auth.profile.notify
  },
  methods: {
    clearGlobalFilterEmail() {
      this.globalFilters.email = ''
      this.$store.dispatch(STORE_SET_EMAIL_FILTER, this.globalFilters.email)
    },
    async simulateUser() {
      if (this.globalFilters.email) this.showWatchAs = true
      this.$store.dispatch(STORE_SET_EMAIL_FILTER, this.globalFilters.email)
      setTimeout(() => { this.showWatchAs = false }, 1000)
      if (this.globalFilters.email) {
        try {
          const email = this.globalFilters.email.length !== 0 ? this.globalFilters.email : ''
          const data = { user_email: email, to_update: false }
          const result = await this.$http.updateProfile(data)
          this.notify = result.notify
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }
    },
    submitForm() {
      this.$refs.submitButton.click()
    },
    async updateNotify() {
      this.updateNotifyMessage = ''
      const email = this.globalFilters.email.length !== 0 ? this.globalFilters.email : ''
      const data = { notify: !this.notify }
      if (this.$store.state.auth && this.$store.state.auth.user.is_staff) {
        data.user_email = email
      }
      try {
        const response = await this.$http.updateProfile(data)
        if (!this.$store.state.auth.user.is_staff) {
          this.$store.dispatch(STORE_SET_NOTIFY, data)
        }
        this.notify = response.notify
        this.updateNotifyMessage = this.$t('updateSuccess')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    },
    async updateUser() {
      this.formError = ''
      this.formSuccess = ''
      if (this.newPassword !== this.newPassword2) this.formError = this.$t('passwordMismatch')
      // else if (/\d/.test(this.first_name) || /\d/.test(this.last_name)) this.formError = this.$t('userFormValidatorFirstLastNameError')
      else if (!this.oldPassword) this.formError = this.$t('emptyPasswordFormValidator')
      else if (!this.first_name || !this.last_name) this.formError = this.$t('emptyFirstLastNameValidator')
      else {
        const name = { first_name: this.first_name, last_name: this.last_name }
        const data = {
          ...name,
          password: this.oldPassword,
          new_password: this.newPassword,
          new_password2: this.newPassword2
        }
        try {
          await this.$http.updateUser(data)
          this.$store.dispatch(STORE_SET_NAME, name)
          this.oldPassword = ''
          this.formSuccess = this.$t('changeSaveSuccess')
        } catch (error) {
          this.formError = this.$t('changeSaveError')
        }
      }
    }
  }
}
