// STORE CONSTANTS FOR PROFESSIONALS

export const STORE_CHANGE_PROFESSIONAL_SECTION_TITLE = 'CHANGE_PROFESSIONAL_SECTION_TITLE'
export const STORE_SET_PAYMENT_NOTES = 'STORE_SET_PAYMENT_NOTES'
export const STORE_CLEAR_PAYMENT_NOTES = 'STORE_CLEAR_PAYMENT_NOTES'

// ROUTER PATH CONSTANTS FOR PROFESSIONALS

export const PATH_PROFESSIONAL_MAIN = '/middlemen'
export const PATH_PROFESSIONAL_FEES_LIST = `${PATH_PROFESSIONAL_MAIN}/fees`
export const PATH_PROFESSIONAL_PAYMENTNOTES = `${PATH_PROFESSIONAL_MAIN}/payment-notes`
export const PATH_PROFESSIONAL_NOT_FOUND = '*'
