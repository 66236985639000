export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    check() {
      if (!this.disabled || (this.disabled && this.value)) {
        this.$emit('input', !this.value)
      }
    },
  },
}
