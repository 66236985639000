export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    messageClass: {
      type: String,
      default: '',
    },
  },
}
