import Vue from 'vue'
import Router from 'vue-router'
import { isAuthenticated } from '@/utils'
import AuthRoutes from '@/auth/authRoutes'
import ClientRoutes from '@/clients/clientsRoutes'
import ProfessionalsRoutes from '@/professionals/professionalsRoutes'
import { I, Offline, NotFound404 } from '@/shared/views'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: '/',
      name: 'START',
      component: I,
    },
    {
      path: '/offline',
      name: 'OFFLINE',
      component: Offline,
    },
    ...AuthRoutes,
    ...ClientRoutes,
    ...ProfessionalsRoutes,
    {
      path: '*',
      name: 'NOT_FOUND_404',
      component: NotFound404,
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.protected) {
    if (isAuthenticated()) {
      next()
    } else {
      next({ name: 'LoginView', query: { next: to.path } })
    }
  } else {
    next()
  }
})

export default router
