import {
  BaseForm,
  ChangeAddress,
  ChangeContractAdmin,
  ChangePaymentData,
  ChangePower,
  SupplyReading,
  ChangeAdmin,
  InvoiceIssue,
  InvoiceIntegrationRequest,
  Other,
} from '@/clients/forms'
import { TICKET_TYPES, TICKET_STATUSES } from '@/config'
import {
  Card, SectionHeader, Notification, VueModal, Loading
} from '@/shared/components'
import { isUserStaff } from '@/utils'
import { UIStatus } from '@/shared/mixins'

export default {
  components: {
    BaseForm,
    Card,
    ChangeAddress,
    ChangeAdmin,
    ChangeContractAdmin,
    ChangePaymentData,
    ChangePower,
    InvoiceIssue,
    InvoiceIntegrationRequest,
    Notification,
    Other,
    SectionHeader,
    SupplyReading,
    VueModal,
    Loading
  },
  mixins: [UIStatus],
  props: {
    selected: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showModal: false,
      currentForm: this.$route.params.type ? this.$route.params.type : '',
      message: '',
      active: true,
      snackBarMessage: null,
      TICKET_TYPES,
      TICKET_STATUSES,
    }
  },
  computed: {
    objectSelected() { return this.$route.params.selected },
    snackBarActive() { return this.snackBarMessage !== null },
    isStaff() { return isUserStaff() },
    type() {
      if (this.loading) {
        return 'has-dark-blue-background'
      }
      if (this.error) {
        return 'has-red-background'
      }
      return 'has-primary-background'
    }
  },
  methods: {
    async postTicket(ticket) {
      this.active = false
      this.message = ''
      this.loading = true
      try {
        if (ticket.error) throw new Error(ticket.message || this.$t('missingRequiredParams'))
        const newTicket = await this.$http.post('tickets', ticket)
        // TODO: improve this hardcoded HTML and
        if (newTicket.id) {
          this.snackBarMessage = `${this.$t('ticketCreated')} <a href="/customer/ticket/${newTicket.id}">${this.$t('viewTicket')}</a>`
        } else {
          this.snackBarMessage = `${this.$t('requestSend')}`
        }
      } catch (error) {
        this.message = this.$t('error')
        this.setError(error.message)
      }
      this.loading = false
      this.active = true
    },
    toggleModal() {
      this.showModal = !this.showModal
    },
    closeSnackBar() {
      this.snackBarMessage = null
    }
  },
}
