export default {
  props: {
    icon: { type: String, default: '' },
    text: { type: String, default: '' },
    color: {
      validator: mColor => ['danger', 'warning', 'neutral', 'success'].indexOf(mColor) > -1,
      default: 'neutral',
      type: String
    },
    klass: { type: String, default: '' },
  }
}
