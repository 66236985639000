import MessageText from '../MessageText'
import { download } from '@/utils'

export default {
  components: {
    MessageText
  },
  props: {
    ticket: {
      type: Object,
      required: true
    },
    showAttachMessage: Boolean,
    isAttaching: Boolean
  },
  data() {
    return {
      file: undefined,
      message: ''
    }
  },
  mounted() {
    const textArea = this.$refs.textArea || null;
    if (textArea) {
      textArea.addEventListener('keydown', (ev) => {
        if (ev.keyCode === 13 && (ev.ctrlKey || ev.metaKey)) { // ctrl + enter or cmd + enter
          this.post()
        }
      })
    }
  },
  methods: {
    resizeTextArea(ev) {
      const baseHeight = 40
      const padding = 20
      const maxHeight = 250
      const textarea = this.$el.querySelector('textarea')
      const div = document.createElement('div')
      const value = ev.type === 'paste' ? ev.clipboardData.getData('text') : textarea.value
      div.innerHTML = value.replace(/(?:\r\n|\r|\n)/g, '<br>&nbsp;');
      textarea.parentNode.appendChild(div)
      const height = padding + div.clientHeight
      div.remove()
      if (height < baseHeight) {
        textarea.style.height = `${baseHeight}px`
      } else if (height < maxHeight) {
        textarea.style.height = `${height}px`
        window.scrollTo(0, document.body.scrollHeight)
      } else {
        textarea.style.height = `${maxHeight}px`
        window.scrollTo(0, document.body.scrollHeight)
      }
    },
    receives(msg) {
      const msgUser = msg.user || ''
      const msgUsername = msgUser.username || ''
      const user = this.$store.state.auth.user || {}
      const authUsername = user.username || null
      return msgUsername !== authUsername
    },
    input() {
      this.$emit('input', this.message)
    },
    post() {
      this.$emit('post')
      this.message = ''
      this.input()
    },
    openFileExplorer() {
      const input = this.$refs['upload-file']
      input.click()
    },
    dropFiles(ev) {
      const droppedFiles = ev.dataTransfer.files
      if (!droppedFiles) return
      this.$emit('dropped-files', [...droppedFiles])
      this.hideDropZone()
    },
    fileSelected(event) {
      const { files } = event.target
      if (files.length !== 0) {
        const [f] = files
        this.file = f
      }
      this.$emit('selected-file', this.file)
    },
    async downloadFile() {
      const { file } = this.ticket
      const fileExtension = this.ticket.file_extension
      const { id } = this.$route.params
      try {
        this.$emit('download-file')
        const data = await this.$http.downloadTicketFile(file)
        download(data, `ticket-${id}.${fileExtension}`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
      this.$emit('finished-download')
    },
    getDropZone() {
      return this.$el.querySelector('.drop-zone')
    },
    showDropZone() {
      const dropZone = this.getDropZone()
      if (dropZone.style.display !== 'flex') {
        const sendMessage = this.$el.querySelector('.send-message')
        dropZone.style.height = `${sendMessage.clientHeight - 25}px`
        dropZone.style.width = `${sendMessage.clientWidth - 30}px`
        dropZone.style.opacity = 1
        dropZone.style.display = 'flex'
      }
    },
    hideDropZone() {
      const dropZone = this.getDropZone()
      if (dropZone.style.display !== 'none') {
        dropZone.style.opacity = 0
        setTimeout(() => { dropZone.style.display = 'none' }, 500)
      }
    },
    onRemoveMsg(value) {
      this.$emit('removeMsg', value)
    },
    // eslint-disable-next-line no-console
    log(msg) { console.log('log', msg) }
  },
}
