export default {
  props: {
    /**
       * Receives an array of objects with the following properties: id, text
       */
    options: {
      type: Array,
      required: true
    },
    /**
       * Whether is filterable or not
       */
    filterable: {
      type: Boolean,
      required: false
    },
    /**
       * Filter function
       */
    filter: {
      type: Function,
      required: false,
      default: undefined
    },
    /**
       * Text formatting function
       */
    format: {
      type: Function,
      required: false,
      default: undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      query: ''
    }
  },
  computed: {
    dataset() {
      if (this.filterable) {
        return this.options.filter(option => (
          option.text.toLowerCase().startsWith(this.query.toLowerCase())
        ))
      }
      return this.options
    }
  },
  methods: {
    clear() {
      this.query = ''
    },
    emit(option) {
      this.$emit('select', option)
      this.$emit('input', option.id)
    }
  },
}
