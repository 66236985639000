export default {
  data() {
    return {
      filters: {},
      filtersConfig: {},
      hasFilters: false,
      orderingFilterKey: 'ordering',
      filtersStorageKey: null,
      searchOptionsConfig: []
    }
  },
  computed: {
    searchOptions() {
      return this.searchOptionsConfig.map((option) => {
        if (option.id in this.filters) {
          option.selected = true
          option.value = this.filters[option.id]
        } else {
          option.selected = false
          option.value = ''
        }
        return option
      })
    }
  },
  watch: {
    filters: {
      handler() {
        const filtersToSave = Object.keys(this.filters).filter(key => key !== this.orderingFilterKey && this.filters[key])
        if (filtersToSave.length > 0) {
          this.saveFilters()
        }
      },
      deep: true
    },
  },
  methods: {
    loadFilters() {
      this.filters = Object.assign({}, this.filtersConfig)
      const savedFilters = localStorage ? localStorage.getItem(this.filtersStorageKey) : null
      if (savedFilters) {
        this.filters = JSON.parse(savedFilters)
      }
    },
    saveFilters() {
      if (localStorage) {
        localStorage.setItem(this.filtersStorageKey, JSON.stringify(this.filters))
      }
      this.hasFilters = true
    },
    cleanFilters() {
      const ordering = this.filters[this.orderingFilterKey] || null
      this.filters = ordering ? { ordering } : {}
      if (localStorage) {
        localStorage.removeItem(this.filtersStorageKey)
      }
      this.hasFilters = false
    }
  }
}
