export default {
  methods: {
    removeLinks(message) {
      return message.replace('href="https:', 'href="')
    },
    addDownloadToken(url) {
      return `${url}?token=${this.$http.getToken()}`
    },
    parseMessage(message) {
      const urlParts = window.location.href.split('/')
      const APP_BASE_URL = `${urlParts[0]}//${urlParts[2]}`
      const REPLACE_STR = `${APP_BASE_URL}/customer/files/download/$1`
      const REPLACE_STR_WITH_LINK = `<a href="${REPLACE_STR}" target="_blank">${REPLACE_STR}</a>`
      let parsedMessage = this.removeLinks(message)
      parsedMessage = parsedMessage.replace(/https?:\/\/[A-Za-z0-9.-]+?\/customer\/files\/download\/([0-9]+)/ig, REPLACE_STR_WITH_LINK)
      parsedMessage = parsedMessage.replace(/https?:\/\/[A-Za-z0-9.-]+?\/api\/1.0\/files\/([0-9]+)/ig, REPLACE_STR_WITH_LINK)
      parsedMessage = parsedMessage.replace(/https?:\/\/[A-Za-z0-9.-]+?\/clientes\/download\/file\/([0-9]+)\/.+/ig, REPLACE_STR_WITH_LINK)
      parsedMessage = parsedMessage.replace(/https?:\/\/[A-Za-z0-9.-]+?\/clientes\/download\/sepa\/([0-9]+)/ig, REPLACE_STR_WITH_LINK)
      parsedMessage = parsedMessage.replace(/https?:\/\/[A-Za-z0-9.-]+?\/api\/1.0\/files\/([0-9]+)/ig, REPLACE_STR_WITH_LINK)
      return parsedMessage
    }
  }
}
