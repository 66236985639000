export default {
  props: {
    options: {
      type: Array,
      validator: (opts) => {
        // Every option must include the properties [id] and [text]
        let ok = true
        opts.forEach((option) => {
          ok = ok && Object.keys(option).indexOf('id') > -1 && Object.keys(option).indexOf('text') > -1
        })
        return ok
      },
      default: () => []
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    makeQuery() {
      this.$emit('input', this.query)
    },
    clear() {
      this.query.text = ''
      this.makeQuery()
    },
  },
  computed: {
    query() {
      const selectedOptions = this.options.filter(option => option.selected || false)
      let query = {
        option: selectedOptions.length > 0 ? selectedOptions[0].id : null,
        text: selectedOptions.length > 0 ? selectedOptions[0].value : null
      }
      if (query.option === null) {
        query = {
          option: this.options.length > 0 ? this.options[0].id : '',
          text: ''
        }
      }
      return query
    }
  }
}
