import { Loading } from '@/shared/components'
import { UIStatus } from '@/shared/mixins'

export default {
  components: {
    Loading
  },
  mixins: [UIStatus],
  props: {
    resource: {
      type: String,
      required: true
    },
    format: {
      type: Function,
      default: (item) => {
        if (item || false) {
          return item.id || item.idn
        }
        return ''
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    q: {
      type: String,
      default: 'search'
    },
    required: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object,
      default: () => {},
    },
    value: {},
    watch: {
      type: Boolean,
      default: false
    },
    freetext: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: this.selected ? this.toFormat(this.selected) : '',
      items: [],
      hidden: false,
      isFreeText: false,
      selectedItem: this.selected ? this.selected : undefined,
    }
  },
  computed: {
    isVisible() {
      return Object.keys(this.query).length > 0
    }
  },
  watch: {
    async value() {
      if (this.value == null) {
        this.clearSelectedItem()
      }
      if (this.watch && this.value !== null && this.query.length === 0) {
        this.query = this.value
        await this.makeQuery()
      }
    },
    query() {
      if (this.query === '') {
        this.selectedItem = undefined
      }
    }
  },
  methods: {
    async fetchItems() {
      this.items = []
      if (this.query.length === 0) return

      try {
        this.setLoading()
        this.isFreeText = false
        const params = {
          [this.q]: this.query
        }
        this.selectedItem = null
        const items = await this.$http.list(this.resource, params)
        this.items = items.results
        if (this.items.length === 1) {
          this.selectItem(this.items[0])
        } else {
          this.items.forEach((item) => {
            if (this.selectedItem == null && (this.query === `${item.id}` || this.query === `${item.idn}` || this.query === `${item.code}`)) {
              this.selectItem(item)
            }
          })
        }
        if (this.selectedItem == null && this.freetext && this.items.length === 0) {
          this.isFreeText = true
          const item = {}
          item[this.q] = this.query
          this.selectItem(item)
        }
        this.setIdeal()
      } catch (error) {
        this.setError(error.message)
      }
    },
    async makeQuery() {
      this.selectedItem = null
      await this.fetchItems()
    },
    selectItem(item) {
      this.query = this.toFormat(item)
      this.selectedItem = item
      this.hidden = true
      this.$emit('input', this.selectedItem)
    },
    clearSelectedItem() {
      this.query = ''
      this.selectedItem = null
      this.$emit('input', this.selectedItem)
    },
    toFormat(item) {
      if (item) {
        if (this.isFreeText) {
          return item[this.q]
        }
        return this.format(item)
      }
      return ''
    }
  },
}
