import UIStatus from '@/shared/mixins/UIStatus'
import { STORE_LOGIN } from '@/auth/authConstants'
import { STORE_SET_EMAIL_FILTER } from '@/clients/clientsConstants'

import {
  Notification,
  Loading
} from '@/shared/components'

export default {
  components: {
    Notification,
    Loading
  },
  mixins: [UIStatus],
  data() {
    return {
      username: '',
      password: '',
      remember: false,
      error: '',
      loading: false,
    }
  },
  computed: {
    disabled() {
      return !(this.username && this.password)
    },
    errorMessage() {
      switch (this.error.status) {
        case 500:
          return this.$t('error500')
        case 400:
          return this.$t('loginError')
        case 401:
          return this.$t('userNotActiveContractsError')
        case 403:
          return this.$t('noUserError')
        default:
          return null
      }
    }
  },
  methods: {
    async login() {
      this.error = ''
      this.loading = true
      try {
        const result = await this.$http.login(this.username, this.password)
        this.$store.dispatch(STORE_LOGIN, result)
        this.$store.dispatch(STORE_SET_EMAIL_FILTER, '')
        let to = { name: 'START' }
        if (this.$route.query.next || false) {
          to = { path: this.$route.query.next }
        }
        this.$router.push(to, () => {})
      } catch (error) {
        const response = error.response || {}
        this.setError({
          status: response.status || '',
          message: error.message
        })
      }
      this.loading = false
    }
  },
}
