import { render, staticRenderFns } from "./ClientTicketsListView.html?vue&type=template&id=15d302ca&scoped=true&"
import script from "./ClientTicketsListView.js?vue&type=script&lang=js&"
export * from "./ClientTicketsListView.js?vue&type=script&lang=js&"
import style0 from "./ClientTicketsListView.scss?vue&type=style&index=0&id=15d302ca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d302ca",
  null
  
)

export default component.exports