import {
  BadgeDropdown
} from '@/shared/components'

export default {
  components: {
    BadgeDropdown
  },
  props: {
    options: {
      type: Array,
      required: true,
      validator: (opts) => {
        // Every option must include the properties [id] and [text]
        let ok = true
        opts.forEach((option) => {
          ok = ok && Object.keys(option).indexOf('id') > -1 && Object.keys(option).indexOf('text') > -1
        })
        return ok
      }
    },
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      display: false
    }
  },
  methods: {
    toggleOptions() {
      this.display = !this.display
    },
    select(option) {
      this.$emit('select', option)
      this.display = false
    }
  }
}
