export default {
  props: {
    contract: {
      type: Object,
      required: false
    },
    invoice: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      duplicateTickets: []
    }
  },
  computed: {
    hasOpenTicket() {
      return this.duplicateTickets.length > 0
    }
  },
  watch: {
    contract: {
      handler: 'contractHasDuplicatedTickets',
      deep: true
    },
    invoice: {
      handler: 'invoiceHasDuplicatedTickets',
      deep: true
    }
  },
  methods: {
    async contractHasDuplicatedTickets() {
      if (this.contract && this.contract.id !== undefined) {
        // request
        const params = { contract_id: this.contract.id, status: 'OPE' }
        const results = await this.$http.list('tickets', params)
        if (results.count !== 0) {
          this.duplicateTickets = results.results
        }
      } else {
        this.duplicateTickets = []
      }
    },
    async invoiceHasDuplicatedTickets() {
      if (this.invoice && this.invoice.id !== undefined) {
        // request
        const params = { invoice_id: this.invoice.id, status: 'OPE' }
        const results = await this.$http.list('tickets', params)
        if (results && results.count !== 0) {
          this.duplicateTickets = results.results
        }
      } else {
        this.duplicateTickets = []
      }
    }
  }
}
