export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'arrow',
      validator: value => ['cross', 'arrow'].indexOf(value) > -1
    }
  },
}
