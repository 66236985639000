import {
  PATH_LOGIN,
  PATH_RECOVER_PASSWORD,
  PATH_RECOVER_PASSWORD_CONFIRM
} from '@/auth/authConstants'
import LoginView from '@/auth/views/AuthLoginView'
import RecoverPasswordView from '@/auth/views/RecoverPasswordView'
import RecoverPasswordConfirmView from '@/auth/views/RecoverPasswordConfirmView'

export default [
  {
    path: PATH_LOGIN,
    name: 'LoginView',
    component: LoginView,
    protected: false,
  },
  {
    path: PATH_RECOVER_PASSWORD,
    name: 'RecoverPasswordView',
    component: RecoverPasswordView,
    protected: false
  },
  {
    path: PATH_RECOVER_PASSWORD_CONFIRM,
    name: 'RecoverPasswordConfirmView',
    component: RecoverPasswordConfirmView,
    protected: false
  }
]
