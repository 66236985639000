import { mapState } from 'vuex'
import {
  ListView,
  SectionHeader
} from '@/shared/components'
import UIStatus from '@/shared/mixins/UIStatus'
import Pagination from '@/shared/mixins/Pagination'
import { download, removeEmptyProperties as _ } from '@/utils'
import { STORE_SET_PAYMENT_NOTES, STORE_CLEAR_PAYMENT_NOTES } from '@/professionals/professionalsConstants'

export default {
  components: {
    ListView,
    SectionHeader,
  },
  mixins: [UIStatus, Pagination],
  data() {
    return {
      checkedPaymentNotes: [],
      downloadActive: false,
      filters: {
        page: 1,
        email: this.$store.state.clients.globalFilters.email
      }
    }
  },
  computed: mapState({
    paymentNotes: state => state.middleman.paymentNotes.results,
    total: state => state.middleman.paymentNotes.count,
    currentPage: state => state.middleman.paymentNotes.page,
    isEmpty: state => state.middleman.paymentNotes.results.length === 0,
    hasNextPage: state => state.middleman.paymentNotes.next !== null,
    globalFilters: state => state.clients.globalFilters,
    disableScroll() { return !(this.hasNextPage) },
  }),
  created() {
    this.setBlank()
    this.$store.dispatch(STORE_CLEAR_PAYMENT_NOTES)
    this.fetchPaymentNotes()
  },
  methods: {
    async fetchPaymentNotes() {
      try {
        this.setLoading()
        const paymentNotes = await this.$http.getPaymentNotes(_(this.filters))
        this.$store.dispatch(STORE_SET_PAYMENT_NOTES, paymentNotes)
        this.setIdeal()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
        this.setError(error.message)
      }
    },
    async downloadPaymentNote(paymentNote) {
      const { agreement } = paymentNote
      let { month, year } = paymentNote
      const { file } = paymentNote.files[0]
      try {
        this.downloadActive = true
        const data = await this.$http.downloadPaymentNote(file)
        if (month < 12) {
          month += 1
        } else {
          month = 1
          year += 1
        }
        download(data, `${agreement}-${year}-${month}.pdf`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
      this.downloadActive = false
    },
    closeSnackbar() {
      this.downloadActive = false
    },
    nextPage() {
      if (!this.hasNextPage || this.loading) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchPaymentNotes()
      })
    },
    setCheckedPaymentNotes(paymentNotes) {
      this.checkedPaymentNotes = paymentNotes
    },
    setFilter(filter, value) {
      this.filters[filter] = value
      //   this.$store.dispatch(STORE_CLEAR_PAYMENT_NOTES)
      this.page = 1
      this.fetchPaymentNotes()
    }
  },
}
