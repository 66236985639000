export default {
  beforeMount() {
    window.addEventListener('sw-online', this.online)
  },
  methods: {
    online() {
      this.$router.push({ name: 'START' }, () => {})
    }
  }
}
