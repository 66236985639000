import { validationMixin } from 'vuelidate'

export default {
  mixins: [validationMixin],
  methods: {
    formInputClass(item) {
      return {
        invalid: item.$dirty && item.$invalid,
        valid: item.$dirty && !item.$invalid
      }
    }
  }
}
