import { Card, Loading } from '@/shared/components'

export default {
  components: { Card, Loading },
  data() {
    return {
      loading: true
    }
  },
  async created() {
    const { idn } = this.$route.params
    const response = await this.$http.list('invoices', { idn })
    if (response.count > 0) {
      const invoice = response.results[0]
      this.$router.push({ name: 'ClientInvoiceView', params: { id: invoice.id } }, () => {})
    } else {
      this.loading = false
    }
  }
}
