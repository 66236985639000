import * as Sentry from '@sentry/browser'
import { isMiddleman, isCustomer } from '@/utils'
import { STORE_LOGOUT, PATH_LOGIN } from '@/auth/authConstants'
import { STORE_SET_EMAIL_FILTER } from '@/clients/clientsConstants'
import NavButton from '../NavButton'

export default {
  components: {
    NavButton
  },
  data() {
    return {
      active: false,
      drop: false,
    }
  },
  computed: {
    isProfessional() { return isMiddleman() },
    isCustomer() { return isCustomer() }
  },
  created() {
    Sentry.configureScope((scope) => {
      scope.setUser({ username: this.$store.state.auth.user.username })
      scope.setExtra('state', this.$store.state);
    })
  },
  methods: {
    toggle() {
      this.active = !this.active
    },
    toggleDropdown() {
      this.drop = !this.drop
    },
    logout() {
      this.$store.dispatch(STORE_LOGOUT)
      this.$router.push(PATH_LOGIN, () => {})
    },
    removeWatchAs() {
      this.$store.dispatch(STORE_SET_EMAIL_FILTER, '')
    },
  },
}
