import { breakpoints } from '@/config'

export default {
  data() {
    return {
      uiStatus: '',
      error: '',
      loading: false,
      width: window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    })
  },
  methods: {
    setLoading() {
      this.uiStatus = 'loading'
      this.loading = true
      this.error = ''
    },
    setIdeal() {
      this.uiStatus = 'ideal'
      this.loading = false
      this.error = ''
    },
    setError(error) {
      this.uiStatus = 'error'
      this.error = error
      this.loading = false
    },
    setPartial() {
      this.uiStatus = 'partial'
      this.loading = false
      this.error = ''
    },
    setBlank() {
      this.uiStatus = 'blank'
      this.loading = false
      this.error = ''
    },
    // Breakpoints
    isMobile() { return this.width < breakpoints.tablet },
    isTablet() { return this.width < breakpoints.desktop },
    isDesktop() { return this.width < breakpoints.widescreen && this.width >= breakpoints.desktop },
    isWidescreen() {
      return this.width < breakpoints.fullhd && this.width >= breakpoints.widescreen
    },
    isFullHD() { return this.width >= breakpoints.fullhd },
    // UI Status
    isLoading() { return this.loading },
    isPartial() { return this.uiStatus === 'partial' },
    isBlank() { return this.uiStatus === 'blank' },
    isIdeal() { return this.uiStatus === 'ideal' },
    isError() { return this.uiStatus === 'error' },
  }
}
