import { required } from 'vuelidate/lib/validators'
import { Form } from '@/shared/mixins'
import TicketGenerator from '../TicketGenerator'

export default {
  mixins: [Form],
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    defaultSubject: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  validations: {
    subject: { required },
    comments: { required }
  },
  data() {
    return {
      subject: this.defaultSubject ? this.defaultSubject : '',
      comments: '',
    }
  },
  methods: {
    reset() {
      this.subject = ''
      this.comments = ''
      this.$v.$reset()
    },
    postTicket() {
      const ticket = TicketGenerator.default({
        subject: this.subject,
        comments: this.comments,
        type: this.type
      })
      this.$emit('send', ticket)
      this.reset()
    },
  }
}
