/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import axios from 'axios'
import store from '@/store'
import { STORE_LOGOUT } from '@/auth/authConstants'

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'

export class BaseHttp {
  BASE_URL = process.env.VUE_APP_API_URL

  API_URL = '/'

  HTTP_AUTHORIZATION_HEADER = 'Token'

  token = ''

  constructor() {
    this.token = ''
    this.requests = {}
  }

  /**
   *
   * @param {String} resource Resource
   * @param {String} path     Extended path
   */
  getUrl(resource, path = '') {
    return `${this.API_URL}/${resource}/${path}`
  }

  /**
   * Returns the current token
   */
  getToken() {
    return this.token
  }

  /**
   * Sets the token
   * @param {String} token The token
   */
  setToken(token) {
    this.token = token
  }

  /**
   * Returns a axios configuration object given the following parameters
   * @param {String} method       HTTP Method
   * @param {String} url          Target URL
   * @param {Object} data         [Optional]: Body of the HTTP Post request
   * @param {Object} params       [Optional]: HTTP GET Parameters
   * @param {Object} extraConfig  [Optional]: Extra Axios configuration
   * @param {Object} headers      [Optional]: HTTP requests headers
   * @param {String} token        [Optional]: JWT Token
   *
   * @returns {Object}            Axios configuration object
   */
  _makeAxiosConfig(method, url, {
    data = {}, params = {}, extraConfig = {}, headers = {}, token = ''
  }) {
    let newHeaders = headers
    if (token) {
      newHeaders = {
        ...headers,
        Authorization: `${this.HTTP_AUTHORIZATION_HEADER} ${token}`
      }
    }

    return {
      method, url, params, data, headers: newHeaders, ...extraConfig,
    }
  }

  /**
   * Makes a HTTP request
   * @param {String} method             HTTP Method
   * @param {String} url                Target URL
   * @param {Object} data               [Optional]: Body of the HTTP Post request
   * @param {Object} params             [Optional]: HTTP GET Parameters
   * @param {Object} extraConfig        [Optional]: Extra Axios configuration
   * @param {Object} headers            [Optional]: HTTP requests headers
   * @param {String} token              [Optional]: JWT Token
   * @param {String} responseProperty   [Optional]: Response property to return
   *
   * @returns {Object}            Response data from the server
   * @throws                      Exception to be captured
   */
  async request(method, url, {
    data = {}, params = {}, config = {}, headers = {}, token = '', responseProperty = 'data'
  }, abort = true) {
    if (abort) {
      this.abortRequest(this.requests[url])
    }
    const cancelToken = axios.CancelToken.source()
    this.requests = { ...this.requests, [url]: cancelToken }
    const axiosConfig = this._makeAxiosConfig(method, url, {
      data, params, extraConfig: { ...config, cancelToken: cancelToken.token }, headers, token,
    })
    const response = await axios(axiosConfig)
    return response[responseProperty]
  }

  async abortRequest(source) {
    if (source) {
      source.cancel({ isCancelled: true, reason: 'Duplicate request found' })
    }
  }

  /**
   * Gets a specified item
   * @param {Object} item
   */
  async get(resource, id) {
    const options = { token: this.getToken() }
    const url = this.getUrl(resource, id)

    return this.request('GET', url, options)
  }

  /**
   * Creates a new resource
   * @param {Object} data
   */
  async post(resource, data) {
    const options = { data, token: this.getToken() }
    const url = this.getUrl(resource)

    return this.request('POST', url, options)
  }

  /**
   * Updates a specified item
   * @param id Item id
   * @param {Object} data
   */
  async put(resource, id, data) {
    const options = { data, token: this.getToken() }
    const url = this.getUrl(resource, id)

    return this.request('PUT', url, options)
  }

  /**
   * Deletes a specified item
   * @param {Object} item
   */
  async delete(resource, id) {
    const options = { token: this.getToken() }
    const url = this.getUrl(resource, id)

    return this.request('DELETE', url, options)
  }

  getBaseUrl() {
    return this.API_URL
  }
}

export default class Http extends BaseHttp {
  BASE_URL = process.env.VUE_APP_API_URL

  API_URL = `${this.BASE_URL}/api/1.0`

  HTTP_AUTHORIZATION_HEADER = 'Token'

  LOGIN_URL = `${this.API_URL}/auth/login`

  FEEDBACK_URL = `${this.API_URL}/auth/feedback`

  CHANGE_PASSWORD_URL = `${this.API_URL}/auth/password/change`

  UPDATE_USER_PROFILE = `${this.API_URL}/auth/update/profile`

  UPDATE_USER = `${this.API_URL}/auth/update/user`

  RESET_PASSWORD = `${this.API_URL}/auth/password/reset`

  RESET_PASSWORD_CONFIRM = `${this.API_URL}/auth/password/reset/confirm`

  getToken() {
    const { token } = store.state.auth
    return token
  }

  /**
   * Verifies JWT token
   */
  async verifyToken() {
    const options = { data: { token: this.getToken() } }
    const url = this.getUrl('auth', 'verify-token')
    try {
      return await this.request('POST', url, options)
    } catch (error) {
      // returns true when is a cancelled request in order to token be verified in other side
      const message = error.message || {}
      return message.isCancelled || false
    }
  }

  handleError(error) {
    // eslint-disable-next-line
    console.log('handleError', error)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      store.dispatch(STORE_LOGOUT)
    }
    if (error.response && error.response.status >= 400 && error.response.status < 500) {
      throw error
    }
  }

  /**
   * Returns a resource item list
   *
   * @param {Object} filters Filters applied
   */
  async list(resource, params = {}) {
    const options = { params, token: this.getToken() }
    const url = this.getUrl(resource)

    return this.request('GET', url, options)
  }

  /**
   * Uploads a file
   * @param {File} file     The file to upload
   * @param {String} url    [Optional]: endpoint url
   */
  async upload(file, url, abort = true) {
    const data = new FormData()
    data.append('file', file, file.name)
    const options = {
      data,
      token: this.getToken(),
      headers: {
        'Content-Disposition': `attachment; filename=${file.name}`
      }
    }

    return this.request('POST', url, options, abort)
  }

  /**
   * Returns blob data
   * @param {String} id       Resource id
   * @param {String} type     File type
   */
  async download(url, params = {}, headers = {}) {
    const options = {
      token: this.getToken(), params, config: { responseType: 'blob' }, headers
    }

    return this.request('GET', url, options)
  }

  /**
   * Log in a user
   * @param {String}      username Username
   * @param {String}      password Password
   *
   * @returns {String}    Response data
   */
  async login(username, password) {
    const options = {
      data: { username, password }
    }

    return this.request('POST', this.LOGIN_URL, options)
  }

  /**
   * Changes the user's password
   * @param {String}  oldPassword The old password
   * @param {String}  newPassword The new password
   */
  async changePassword(oldPassword, newPassword) {
    const options = {
      data: {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: newPassword
      },
      token: this.getToken()
    }

    return this.request('POST', this.CHANGE_PASSWORD_URL, options)
  }

  async updateProfile(data) {
    const options = { data, token: this.getToken() }
    return this.request('PUT', this.UPDATE_USER_PROFILE, options)
  }

  async updateUser(data) {
    const options = {
      data,
      token: this.getToken()
    }

    return this.request('PUT', this.UPDATE_USER, options)
  }

  async resetPassword(email) {
    const options = {
      data: {
        email
      }
    }
    return this.request('POST', this.RESET_PASSWORD, options)
  }

  async resetPasswordConfirm(new_password1, new_password2, uid, token) {
    const options = {
      data: {
        new_password1, new_password2, uid, token
      }
    }
    return this.request('POST', this.RESET_PASSWORD_CONFIRM, options)
  }

  /**
   * Returns a resource item list
   *
   * @param {Object} filters Filters applied
   */
  async getReadings(cups, params = { hourly: 1 }) {
    const options = { params, token: this.getToken() }
    const url = this.getUrl('supplies', `${cups}/consumptions`)

    return this.request('GET', url, options)
  }

  async getContractReadings(id, filters) {
    const options = { token: this.getToken(), filters }
    const url = this.getUrl('contracts', `${id}/readings`)

    return this.request('GET', url, options)
  }

  async getFullFees(params, abort = true) {
    const options = { params, token: this.getToken() }
    return this.request('GET', this.getUrl('fees', 'download'), options, abort)
  }

  async getInvoiceReadings(id) {
    const options = { token: this.getToken() }
    const url = this.getUrl('invoices', `${id}/readings`)

    return this.request('GET', url, options)
  }

  async downloadInvoice(id) {
    const url = this.getUrl('invoices', `${id}/download`)
    return this.download(url)
  }

  async downloadInvoicesPDF(params = []) {
    const ids = params.join(',')
    const url = this.getUrl('invoices', `${ids}/download`)
    return this.download(url)
  }

  async downloadContract(id) {
    const url = this.getUrl('contracts', `${id}/download/pdf`)
    return this.download(url)
  }

  /**
   * Returns a resource item list
   *
   * @param {Object} filters Filters applied
   */
  async getSupplyReadings(cups, params = { hourly: 0 }) {
    const options = { params, token: this.getToken() }
    const url = this.getUrl('supplies', `${cups}/consumptions`)

    return this.request('GET', url, options)
  }

  /**
   * Returns a resource item list
   *
   * @param {String} cups
   * @param {Object} filters Filters applied
   */
  async getHourlyReadings(cups, params = { hourly: 1 }) {
    const options = { params, token: this.getToken() }
    const url = this.getUrl('supplies', `${cups}/consumptions`)

    return this.request('GET', url, options)
  }

  /**
   * Returns invoices details
   *
   * @param {String} contract Contract id
   * @param {Object} filters Filters applied
   */
  async getInvoicesConsumptions(idn, params = {}) {
    const options = { params, token: this.getToken() }
    const url = this.getUrl('invoices', `${idn}/maximeter`)

    return this.request('GET', url, options)
  }

  async postComment(id, comment) {
    const data = comment
    const url = this.getUrl('tickets', `${id}/comments`)
    const options = { data, token: this.getToken() }

    return this.request('POST', url, options)
  }

  async deleteComment(id) {
    const url = this.getUrl('tickets', `${id}/delete`)
    const options = { token: this.getToken() }

    return this.request('DELETE', url, options)
  }

  async sendFeedback(message) {
    const data = { message }
    const url = this.FEEDBACK_URL
    const options = { data, token: this.getToken() }

    return this.request('POST', url, options)
  }

  async uploadTicketFile(id, file, abort = true) {
    const url = this.getUrl('tickets', `${id}/file`)
    return this.upload(file, url, abort)
  }

  async getPaymentNotes(params = {}) {
    const url = this.getUrl('payment-notes')
    const options = { params, token: this.getToken() }

    return this.request('GET', url, options)
  }

  async downloadPaymentNote(path) {
    const url = `${this.BASE_URL}${path}`
    return this.download(url)
  }

  async downloadTicketFile(path) {
    const url = `${this.BASE_URL}${path}`
    return this.download(url)
  }

  async getContractAgreement(id) {
    const options = { token: this.getToken() }
    const url = this.getUrl('contracts', `${id}/agreement`)

    return this.request('GET', url, options)
  }
}
