/* eslint-disable no-param-reassign */
import { STORE_SET_PAYMENT_NOTES, STORE_CLEAR_PAYMENT_NOTES } from '@/professionals/professionalsConstants'

export default {
  state: {
    middleman: {
      paymentNotes: {
        next: null,
        previous: null,
        count: 0,
        results: []
      }
    }
  },
  mutations: {
    [STORE_SET_PAYMENT_NOTES](state, payload) {
      state.middleman.paymentNotes.next = payload.next
      state.middleman.paymentNotes.previous = payload.previous
      state.middleman.paymentNotes.count = payload.count
      state.middleman.paymentNotes.results.push(...payload.results)
    },
    [STORE_CLEAR_PAYMENT_NOTES](state) {
      state.middleman.paymentNotes = {
        next: null,
        previous: null,
        count: 0,
        results: []
      }
    }
  },
  actions: {
    [STORE_SET_PAYMENT_NOTES]({ commit }, payload) {
      commit(STORE_SET_PAYMENT_NOTES, payload)
    },
    [STORE_CLEAR_PAYMENT_NOTES]({ commit }) {
      commit(STORE_CLEAR_PAYMENT_NOTES)
    }
  }
}
