import _BaseForm from './BaseForm'
import _ChangeAddress from './contracts/ChangeAddress'
import _ChangeContractAdmin from './contracts/ChangeContractAdmin'
import _ChangePaymentData from './contracts/ChangePaymentData'
import _ChangePower from './contracts/ChangePower'
import _SupplyReading from './contracts/SupplyReading'
import _ChangeAdmin from './internal/ChangeAdmin'
import _InvoiceIssue from './invoices/InvoiceIssue'
import _InvoiceIntegrationRequest from './invoices/InvoiceIntegrationRequest'
import _Other from './other'

export const BaseForm = _BaseForm
export const ChangeAddress = _ChangeAddress
export const ChangeContractAdmin = _ChangeContractAdmin
export const ChangePaymentData = _ChangePaymentData
export const ChangePower = _ChangePower
export const SupplyReading = _SupplyReading
export const ChangeAdmin = _ChangeAdmin
export const InvoiceIssue = _InvoiceIssue
export const InvoiceIntegrationRequest = _InvoiceIntegrationRequest
export const Other = _Other
