export default {
  props: {
    message: {
      type: [String, Object],
      required: false,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.active = !this.active
    }
  }
}
