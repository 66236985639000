import { mapState } from 'vuex'
import Pagination from '@/shared/mixins/Pagination'
import UIStatus from '@/shared/mixins/UIStatus'
import Loading from '@/shared/components/Loading'

export default {
  components: {
    Loading
  },
  mixins: [Pagination, UIStatus],
  props: {
    /**
     * Text formatting function
     */
    format: {
      type: Function,
      required: false,
      default: undefined
    },
    /**
     * Function to transform an item to {id: '', text: ''}
     */
    map: {
      type: Function,
      required: false,
      default: undefined
    },
    resource: {
      type: String,
      required: true
    },
    queryField: {
      type: String,
      default: 'id'
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Object,
      required: false,
      default: () => ({
        count: 0,
        next: null,
        previous: null,
        results: []
      })
    },
    uppercase: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: ''
    }
  },
  computed: {
    options() { return this.data.results.map(item => this.map(item)) },
    hasNextPage() { return this.data.next !== null },
    ...mapState({
      filterEmail: state => state.clients.globalFilters.email || ''
    }),
  },
  created() {
    this.fetchResults()
    this.$emit('results', this.data.results)
  },
  methods: {
    clear() {
      this.query = ''
      this.fetchResults()
    },
    async makeQuery() {
      if (this.query && this.query.length < 3) return
      this.fetchResults()
    },
    async fetchResults() {
      const filters = {
        code: this.uppercase ? this.query.toUpperCase() : this.query,
        page: this.page
      }
      try {
        this.setLoading()
        const params = this.removeEmptyProperties({ ...filters, page: this.page, email: this.filterEmail })
        const response = await this.$http.list(this.resource, params)
        this.data.results = response.results || []
        this.data.previous = response.previous || null
        this.data.next = response.next || null
        this.data.count = response.count || 0
        if (this.query.length > 0 && this.data.count === 0) {
          this.data.results = [{ code: 'No se han encontrado resultados' }]
          this.data.count = 1
        }
        this.$emit('results', this.data.results)
        this.$emit('response', this.data)
        this.setIdeal()
      } catch (error) {
        this.setError(error.message)
      }
    },
    nextPage() {
      if (!this.hasNextPage || this.loading) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchResults()
      })
    },
  },
}
