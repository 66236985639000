var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow",class:{
    'top': _vm.direction === 'top',
    'right': _vm.direction === 'right',
    'bottom': _vm.direction === 'bottom',
    'left': _vm.direction === 'left'
  },style:({
    'border-top': `solid ${_vm.weight}px  ${_vm.color}`,
    'border-right': `solid ${_vm.weight}px  ${_vm.color}`,
    'width': `${_vm.width}px`,
    'height': `${_vm.height}px`
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }