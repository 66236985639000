import { render, staticRenderFns } from "./ChangeAdmin.html?vue&type=template&id=162df048&scoped=true&"
import script from "./ChangeAdmin.js?vue&type=script&lang=js&"
export * from "./ChangeAdmin.js?vue&type=script&lang=js&"
import style0 from "./ChangeAdmin.scss?vue&type=style&index=0&id=162df048&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162df048",
  null
  
)

export default component.exports