import { Notification, Loading } from '@/shared/components'
import { UIStatus } from '@/shared/mixins'

export default {
  components: {
    Loading,
    Notification,
  },
  mixins: [UIStatus],
  data() {
    return {
      email: '',
      error: '',
      sent: false
    }
  },
  created() {
    this.token = this.$route.params.token
  },
  methods: {
    async recoverPassword() {
      await this.postEmail()
    },
    async postEmail() {
      try {
        this.setLoading()
        await this.$http.resetPassword(this.email)
        this.sent = true
        this.setIdeal()
      } catch (error) {
        this.setError(error)
      }
    }
  },
}
