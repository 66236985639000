import { ProfessionalView, ProfessionalFeesListView, ProfessionalPaymentNotesView } from './views'
import {
  PATH_PROFESSIONAL_MAIN, PATH_PROFESSIONAL_FEES_LIST, PATH_PROFESSIONAL_PAYMENTNOTES, PATH_PROFESSIONAL_NOT_FOUND
} from '@/professionals/professionalsConstants'
import { NotFound404 } from '@/shared/views'
import { isMiddleman } from '@/utils'

function professionalGuard(to, from, next) {
  if (isMiddleman()) {
    next()
  } else {
    next({ name: 'LoginView', query: { next: to.path } })
  }
}

export default [{
  path: PATH_PROFESSIONAL_MAIN,
  component: ProfessionalView,
  children: [
    {
      path: PATH_PROFESSIONAL_MAIN,
      name: 'ProfessionalsSTART',
      redirect: PATH_PROFESSIONAL_FEES_LIST
    },
    {
      path: PATH_PROFESSIONAL_FEES_LIST,
      name: 'ProfessionalFeesListView',
      component: ProfessionalFeesListView,
      beforeEnter: professionalGuard
    },
    {
      path: PATH_PROFESSIONAL_PAYMENTNOTES,
      name: 'ProfessionalPaymentNotesView',
      component: ProfessionalPaymentNotesView,
      beforeEnter: professionalGuard
    },
    {
      path: PATH_PROFESSIONAL_NOT_FOUND,
      name: 'ProfessionalNotFound404',
      component: NotFound404
    }
  ]
}]
