export default {
  props: {
    checkable: {
      type: Boolean,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    onCheck: {
      type: Function,
      required: false,
      default: undefined
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      checkedItems: []
    }
  },
  computed: {
    checkedAll() {
      return this.checkedItems.length === this.items.length
    }
  },
  methods: {
    check(item) {
      if (this.isChecked(item)) {
        this.checkedItems.splice(this.checkedItems.indexOf(item), 1)
      } else {
        this.checkedItems.push(item)
      }
      if (this.onCheck) this.onCheck(this.checkedItems)
    },
    checkAll() {
      if (this.checkedAll) {
        this.checkedItems = []
      } else {
        this.checkedItems = []
        this.checkedItems.push(...this.items)
      }
      if (this.onCheck) this.onCheck(this.checkedItems)
    },
    isChecked(item) {
      return this.checkedItems.indexOf(item) > -1
    },
  },
}
