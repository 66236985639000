import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFileContract, faUser, faChevronDown, faLightbulb, faEllipsisV,
  faFileInvoice, faCity, faCalendarAlt, faCaretDown, faInfoCircle,
  faTimes, faUserCheck, faLock, faFileUpload, faPaperPlane, faPlus,
  faBars, faCheck, faCheckDouble, faCog, faDownload, faSearch, faUndo,
  faRedo, faPaperclip, faFileInvoiceDollar, faArrowRight, faFileCsv, faFilePdf, faFileExcel, faTrashAlt,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

library.add(faCalendarAlt)
library.add(faCaretDown)
library.add(faCheck)
library.add(faCheckDouble)
library.add(faCity)
library.add(faFileContract)
library.add(faFileInvoice)
library.add(faInfoCircle)
library.add(faLightbulb)
library.add(faEllipsisV)
library.add(faTimes)
library.add(faUser)
library.add(faUserCheck)
library.add(faLock)
library.add(faFileUpload)
library.add(faPaperPlane)
library.add(faPlus)
library.add(faChevronDown)
library.add(faBars)
library.add(faCog)
library.add(faDownload)
library.add(faSearch)
library.add(faUndo)
library.add(faRedo)
library.add(faPaperclip)
library.add(faArrowRight)
library.add(faFileInvoiceDollar)
library.add(faFileCsv)
library.add(faFilePdf)
library.add(faFileExcel)
library.add(faTrashAlt)
library.add(faExclamationTriangle)
