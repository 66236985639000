import { required, email, decimal } from 'vuelidate/lib/validators'
import { SearchAPI as SearchApi, DuplicatedTickets } from '@/shared/components'
import { RATES } from '@/config'
import TicketGenerator from '../../TicketGenerator'
import { Form } from '@/shared/mixins'

const range = (value, vm) => {
  const rate = vm.currentRate
  if (rate) {
    let periodIndex = 0
    const [min, max] = rate.range
    const periods = [vm.p1, vm.p2, vm.p3, vm.p4, vm.p5, vm.p6]
    for (const periodActive of rate.periods) {
      if (periodActive) {
        const period = parseFloat(periods[periodIndex])
        if (min <= period && (period <= max || max === null)) {
          return true
        }
      }
      periodIndex += 1
    }
  }
  return false
}

const ascendent = (value, vm) => {
  let isOK = true
  const rate = vm.currentRate
  if (rate) {
    let maxPeriod = 0
    let periodIndex = 0
    const periods = [vm.p1, vm.p2, vm.p3, vm.p4, vm.p5, vm.p6]
    for (const periodActive of rate.periods) {
      if (periodActive) {
        const period = parseFloat(periods[periodIndex])
        if (period < maxPeriod) {
          isOK = false
          break
        }
        if (period > maxPeriod) {
          maxPeriod = period
        }
      }
      periodIndex += 1
    }
  }
  return isOK
}

const decimalIfPeriodEnabled = periodNumber => (value, vm) => {
  if (vm.periodIsEnabled(periodNumber)) {
    return required(value) && decimal(value)
  }
  return true
}

export default {
  mixins: [Form],
  components: {
    SearchApi,
    DuplicatedTickets
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      default: undefined
    }
  },
  validations: {
    contract: { required },
    rate: { required },
    contactPhone: {},
    contactEmail: { email },
    p1: { required: decimalIfPeriodEnabled(1), range, ascendent },
    p2: { required: decimalIfPeriodEnabled(2), range, ascendent },
    p3: { required: decimalIfPeriodEnabled(3), range, ascendent },
    p4: { required: decimalIfPeriodEnabled(4), range, ascendent },
    p5: { required: decimalIfPeriodEnabled(5), range, ascendent },
    p6: { required: decimalIfPeriodEnabled(6), range, ascendent }
  },
  data() {
    return {
      contract: this.selected ? this.selected : {},
      rate: this.params ? this.params.rate : undefined,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      comments: '',
      p1: undefined,
      p2: undefined,
      p3: undefined,
      p4: undefined,
      p5: undefined,
      p6: undefined,
      RATES
    }
  },
  computed: {
    currentRate() {
      return this.rate ? RATES.filter(r => r.id === this.rate)[0] : undefined
    }
  },
  watch: {
    async contract() {
      if (this.contract !== null) {
        this.rate = this.contract.rate
        this.p1 = this.contract.power_p1
        this.p2 = this.contract.power_p2
        this.p3 = this.contract.power_p3
        this.p4 = this.contract.power_p4
        this.p5 = this.contract.power_p5
        this.p6 = this.contract.power_p6
      } else {
        this.rate = undefined
        this.p1 = ''
        this.p2 = ''
        this.p3 = ''
        this.p4 = ''
        this.p5 = ''
        this.p6 = ''
      }
    },
    rate() {
      if (this.contract && this.contract.rate !== this.rate) {
        this.p1 = ''
        this.p2 = ''
        this.p3 = ''
        this.p4 = ''
        this.p5 = ''
        this.p6 = ''
      }
    }
  },
  methods: {
    periodIsDisabled(periodNumber) {
      return !this.periodIsEnabled(periodNumber)
    },
    periodIsEnabled(periodNumber) {
      const rates = RATES.filter(item => item.id === this.rate)
      if (rates.length !== 1) {
        return false
      }
      return rates[0].periods[periodNumber - 1]
    },
    postTicket() {
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.changePower({
        contract: this.contract,
        rate: this.rate,
        comments: this.comments,
        contactPhone: this.contactPhone,
        contactEmail: this.contactEmail,
        p1: this.p1,
        p2: this.p2,
        p3: this.p3,
        p4: this.p4,
        p5: this.p5,
        p6: this.p6,
      }, this.type, user)
      this.$emit('send', ticket)
      this.reset()
    },
    isEnabled(source) {
      if (!this.rate) return false

      return RATES.filter(r => r.id === this.rate)[0][source]
    },
    reset() {
      this.contract = {}
      this.rate = ''
      this.comments = ''
      this.p1 = ''
      this.p2 = ''
      this.p3 = ''
      this.p4 = ''
      this.p5 = ''
      this.p6 = ''
      this.$v.$reset()
    },
    copyData() {
      this.contactPhone = this.contract.contact_phone ? this.contract.contact_phone : ''
      this.contactEmail = this.contract.email ? this.contract.email : ''
    },
    showPowerErrors(form) {
      const rate = this.currentRate
      const powers = [form.p1, form.p2, form.p3, form.p4, form.p5, form.p6]
      for (const power of powers) {
        if (power.$dirty && power.$invalid) {
          if (!power.required) {
            return this.$t('powerRequiredOrWrongFormat')
          }
          if (!power.range) {
            let str = 'powerRangeWrong'
            if (rate.range[1] === null) {
              str = 'powerMinRangeWrong'
            }
            return this.$t(str, { min: rate.range[0], max: rate.range[1] })
          }
          if (!power.ascendent) {
            return this.$t('powerNotAscendent')
          }
        }
      }
      return ''
    }
  }
}
