export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    messageClass: { type: String, default: '' },
    icon: { type: String, default: '' },
    dropdown: {
      type: Boolean,
      default: false,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
  },
}
