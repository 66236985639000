/* eslint-disable no-param-reassign */
import {
  STORE_CHANGE_CLIENTS_SECTION_TITLE,
  STORE_SET_AGREEMENTS,
  STORE_SET_CONTRACTS,
  STORE_CLEAR_CONTRACTS,
  STORE_CLEAR_CHECKED_CONTRACTS,
  STORE_CHECK_CONTRACT,
  STORE_SET_INVOICES,
  STORE_CLEAR_INVOICES,
  STORE_CHECK_INVOICE,
  STORE_SET_EMAIL_FILTER,
  STORE_SET_TICKETS,
  STORE_CLEAR_TICKETS,
  STORE_CLEAR_FILES,
  STORE_SET_FILES,
  STORE_CLEAR_MESSAGES,
  STORE_SET_MESSAGES,
  STORE_CLEAR_AGREEMENTS
} from '@/clients/clientsConstants'

export default {
  state: {
    clients: {
      clientsSectionTitle: '',
      agreements: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      contracts: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkedContracts: [],
      invoices: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkedInvoices: [],
      tickets: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      checkedFiles: [],
      files: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      checkedMessages: [],
      messages: {
        count: 0,
        next: null,
        previous: null,
        results: []
      },
      globalFilters: {
        email: ''
      }
    }
  },
  mutations: {
    [STORE_CHANGE_CLIENTS_SECTION_TITLE](state, payload) {
      state.clients.clientsSectionTitle = payload
    },
    [STORE_SET_AGREEMENTS](state, payload) {
      state.clients.agreements = payload
    },
    [STORE_CLEAR_AGREEMENTS](state) {
      state.clients.agreements = {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    },
    [STORE_SET_CONTRACTS](state, payload) {
      const contractsLength = state.clients.contracts.count
      if (contractsLength === 0) state.clients.contracts = payload;
      else {
        state.clients.contracts.next = payload.next
        state.clients.contracts.previous = payload.previous
        payload.results.forEach((element) => {
          if (state.clients.contracts.results) {
            state.clients.contracts.results.push(element)
          }
        })
      }
    },
    [STORE_CLEAR_CONTRACTS](state) {
      state.clients.contracts = {
        count: 0,
        next: '',
        previous: null,
        page: 0,
        results: []
      }
    },
    [STORE_CLEAR_CHECKED_CONTRACTS](state) {
      state.clients.checkedContracts = []
    },
    [STORE_SET_INVOICES](state, payload) {
      const invoicesLength = state.clients.invoices.count
      if (invoicesLength === 0) state.clients.invoices = payload;
      else {
        state.clients.invoices.next = payload.next
        state.clients.invoices.previous = payload.previous
        payload.results.forEach((element) => {
          if (state.clients.invoices.results) {
            state.clients.invoices.results.push(element)
          }
        })
      }
    },
    [STORE_CLEAR_INVOICES](state) {
      state.clients.invoices = {
        count: 0,
        next: '',
        previous: null,
        page: 0,
        results: []
      }
    },
    [STORE_CHECK_INVOICE](state, payload) {
      const { checkedInvoices } = state.clients
      if (payload.checked) checkedInvoices.push(payload.id)
      // else { remove(checkedInvoices, id => id === payload.id) }
    },
    [STORE_SET_TICKETS](state, payload) {
      const ticketsLength = state.clients.tickets.count
      if (ticketsLength === 0) state.clients.tickets = payload;
      else {
        state.clients.tickets.next = payload.next
        state.clients.tickets.previous = payload.previous
        payload.results.forEach((element) => {
          state.clients.tickets.results.push(element)
        })
      }
    },
    [STORE_CLEAR_TICKETS](state) {
      state.clients.tickets = {
        count: 0,
        next: '',
        previous: null,
        page: 0,
        results: []
      }
    },
    [STORE_SET_EMAIL_FILTER](state, payload) {
      state.clients.globalFilters.email = payload
    },
    [STORE_CLEAR_FILES](state) {
      state.clients.files = {
        count: 0,
        next: '',
        previous: null,
        page: 0,
        results: []
      }
    },
    [STORE_SET_FILES](state, payload) {
      const filesLength = state.clients.files.count
      if (filesLength === 0) state.clients.files = payload;
      else {
        state.clients.files.next = payload.next
        state.clients.files.previous = payload.previous
        payload.results.forEach((element) => {
          state.clients.files.results.push(element)
        })
      }
    },
    [STORE_CLEAR_MESSAGES](state) {
      state.clients.messages = {
        count: 0,
        next: '',
        previous: null,
        page: 0,
        results: []
      }
    },
    [STORE_SET_MESSAGES](state, payload) {
      const messagesLength = state.clients.messages.count
      if (messagesLength === 0) state.clients.messages = payload;
      else {
        state.clients.messages.next = payload.next
        state.clients.messages.previous = payload.previous
        payload.results.forEach((element) => {
          state.clients.messages.results.push(element)
        })
      }
    },
  },
  actions: {
    [STORE_CHANGE_CLIENTS_SECTION_TITLE]({ commit }, payload) {
      commit(STORE_CHANGE_CLIENTS_SECTION_TITLE, payload)
    },
    [STORE_SET_AGREEMENTS]({ commit }, payload) {
      commit(STORE_SET_AGREEMENTS, payload)
    },
    [STORE_CLEAR_AGREEMENTS]({ commit }) {
      commit(STORE_CLEAR_AGREEMENTS)
    },
    [STORE_SET_CONTRACTS]({ commit }, payload) {
      commit(STORE_SET_CONTRACTS, payload)
    },
    [STORE_CLEAR_CONTRACTS]({ commit }) {
      commit(STORE_CLEAR_CONTRACTS)
    },
    [STORE_CHECK_CONTRACT]({ commit }, payload) {
      commit(STORE_CHECK_CONTRACT, payload)
    },
    [STORE_CLEAR_CHECKED_CONTRACTS]({ commit }) {
      commit(STORE_CHECK_CONTRACT)
    },
    [STORE_SET_INVOICES]({ commit }, payload) {
      commit(STORE_SET_INVOICES, payload)
    },
    [STORE_CLEAR_INVOICES]({ commit }) {
      commit(STORE_CLEAR_INVOICES)
    },
    [STORE_CHECK_INVOICE]({ commit }, payload) {
      commit(STORE_CHECK_INVOICE, payload)
    },
    [STORE_SET_EMAIL_FILTER]({ commit }, payload) {
      commit(STORE_SET_EMAIL_FILTER, payload)
    },
    [STORE_SET_TICKETS]({ commit }, payload) {
      commit(STORE_SET_TICKETS, payload)
    },
    [STORE_CLEAR_TICKETS]({ commit }) {
      commit(STORE_CLEAR_TICKETS)
    },
    [STORE_SET_FILES]({ commit }, payload) {
      commit(STORE_SET_FILES, payload)
    },
    [STORE_CLEAR_FILES]({ commit }) {
      commit(STORE_CLEAR_FILES)
    },
    [STORE_SET_MESSAGES]({ commit }, payload) {
      commit(STORE_SET_MESSAGES, payload)
    },
    [STORE_CLEAR_MESSAGES]({ commit }) {
      commit(STORE_CLEAR_MESSAGES)
    },
  }
}
