import Pagination from '@/shared/mixins/Pagination'
import UIStatus from '@/shared/mixins/UIStatus'

export default {
  mixins: [UIStatus, Pagination],
  props: {
    resource: {
      type: String,
      required: true
    },
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    filters: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      next: '',
      items: [],
      page: 1
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    async fetchItems() {
      const filters = this.filters || {}
      try {
        this.setLoading()
        const items = await this.$http.list(this.resource, { page: this.page, ...filters })
        this.next = items.next
        this.items.push(...items.results)
        if (this.callback) {
          this.callback(this.items)
        }
        this.setIdeal()
      } catch (error) {
        this.setError(this.$t('error'))
        // eslint-disable-next-line no-console
        console.error('error fetching items', error)
      }
    },
    nextPage() {
      this.updatePage(this.page + 1, async () => {
        await this.fetchItems()
      })
    }
  },
}
