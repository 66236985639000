import { mapState } from 'vuex'
import {
  Arrow,
  Select as MSelect,
  SectionHeader,
  SectionHeaderTag,
  SearchBox,
  Badge,
  BadgeDropdown,
  ListView,
} from '@/shared/components'

import { UIStatus, Pagination } from '@/shared/mixins'
import { isNumber } from '@/utils'
import {
  STORE_SET_TICKETS, STORE_CLEAR_TICKETS
} from '@/clients/clientsConstants'
import PersistentFilters from '@/shared/mixins/PersistentFilters'

export default {
  components: {
    Arrow,
    Badge,
    MSelect,
    ListView,
    SearchBox,
    SectionHeader,
    SectionHeaderTag,
    BadgeDropdown
  },
  mixins: [UIStatus, Pagination, PersistentFilters],
  data() {
    return {
      checkedTickets: [],
      filtersStorageKey: 'tickets.list',
      filtersConfig: {
        status: '',
        ordering: '-id',
        ...this.$route.query,
      },
      statuses: [
        {
          id: '', text: this.$t('anyone')
        },
        {
          id: 'OPE', text: this.$t('open')
        },
        {
          id: 'CLO', text: this.$t('closed')
        },
        {
          id: 'IPR', text: this.$t('inProcessTicket')
        },
      ],
      searchOptionsConfig: [
        {
          id: 'subject',
          text: this.$t('subject')
        },
        {
          id: 'meta',
          text: this.$t('cups')
        },
        {
          id: 'contract_id',
          text: this.$t('contract')
        },
        {
          id: 'id',
          text: this.$t('ticket')
        }
      ]
    }
  },
  computed: mapState({
    tickets: state => state.clients.tickets.results,
    total: state => state.clients.tickets.count,
    isEmpty: state => state.clients.tickets.results.length === 0,
    hasNextPage: state => state.clients.tickets.next != null,
    globalFilters: state => state.clients.globalFilters,
    disableScroll() { return !(this.hasNextPage) },
  }),
  created() {
    this.setBlank()
    this.loadFilters()
    this.$store.dispatch(STORE_CLEAR_TICKETS)
    this.fetchTickets()
  },
  methods: {
    getDropdownMessage() {
      switch (this.filters.status) {
        case 'OPE':
          return this.$t('open')
        case 'CLO':
          return this.$t('closed')
        case 'IPR':
          return this.$t('inProcessTicket')
        default:
          return this.$t('anyStatus')
      }
    },
    async fetchTickets() {
      try {
        const params = this.removeEmptyProperties({
          ...this.filters, page: this.page, ...this.globalFilters
        })
        if (params.id && !isNumber(params.id)) {
          return
        }

        this.setLoading()

        const results = await this.$http.list('tickets', params)
        this.$store.dispatch(STORE_SET_TICKETS, { ...results, page: this.page })
        this.setIdeal()
      } catch (error) {
        if (!error.message.isCancelled) {
          this.error = error.message
          this.setError(error)
        }
      }
    },
    nextPage() {
      if (this.loading || !this.hasNextPage) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchTickets()
      })
    },
    setCheckedTickets(tickets) {
      this.checkedTickets = tickets
    },
    setQueryFilter(filter, value) {
      this.filters = {
        status: this.filters.status,
        [filter]: value
      }
      this.$store.dispatch(STORE_CLEAR_TICKETS)
      this.page = 1
      this.fetchTickets()
    },
    setFilter(filter, value) {
      this.filters[filter] = value
      this.saveFilters()
      this.$store.dispatch(STORE_CLEAR_TICKETS)
      this.page = 1
      this.fetchTickets()
    },
    setOrdering() {
      this.setFilters(this.filters)
    },
    removeFilters() {
      this.cleanFilters()
      this.$store.dispatch(STORE_CLEAR_TICKETS)
      this.fetchTickets()
    },
  }
}
