import Vue from 'vue'
import ChartJS from 'chart.js'
import VueChartKick from 'vue-chartkick'
import InfiniteScroll from 'vue-infinite-scroll'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueTabs from 'vue-nav-tabs'
import { delayDirective } from '@/directives'
import {
  getStatus, today, splitArray, mapArrayToObject, removeEmptyProperties
} from '@/utils'
import { formatDate, formatHour, formatMoney } from './filters'
import '@/fontawesome'
import 'vue-nav-tabs/themes/vue-tabs.css'
import Http from './services/http'
import { Snackbar } from '@/shared/components'

// Components
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('snackbar', Snackbar)


// Directives
Vue.directive('delayInput', delayDirective)

// Filters
Vue.filter('formatDate', formatDate)
Vue.filter('formatHour', formatHour)
Vue.filter('formatMoney', formatMoney)

// Plugins
Vue.use(InfiniteScroll)
Vue.use(VueChartKick, { adapter: ChartJS })
Vue.use(VueTabs)

Vue.config.productionTip = false

Vue.prototype.today = today
Vue.prototype.splitArray = splitArray
Vue.prototype.mapArrayToObject = mapArrayToObject
Vue.prototype.removeEmptyProperties = removeEmptyProperties
Vue.prototype.getStatus = getStatus
Vue.prototype.$http = new Http()
Vue.prototype.theme = process.env.VUE_APP_THEME
export default Vue
