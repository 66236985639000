export const HTTP_AUTHORIZATION_HEADER = 'Token'
export const ITEMS_PER_PAGE = 50
export const BASE_URL = process.env.VUE_APP_BASE_URL
export const API_URL = process.env.VUE_APP_API_URL
export const SENTRY_URL = process.env.VUE_APP_SENTRY_URL
export const DEBUG = process.env.NODE_ENV === 'production'

// UI
const gap = 64
const tablet = 769
const desktop = 960 + (2 * gap)
const widescreen = 1152 + (2 * gap)
const fullhd = 1344 + (2 * gap)

export const TICKET_STATUSES = {
  opened: 'OPE',
  closed: 'CLO',
  inProcess: 'IPR',
}

export const TICKET_TYPES = {
  T_CH_NAME: 'CHN',
  T_CH_NAMEUPLOAD: 'CHU',
  T_CH_POWERUPLOAD: 'CPU',
  T_CH_POWER: 'CPW',
  T_CH_PAYMENT: 'CHP',
  T_CH_SUPPLY: 'CHS',
  T_CH_POSTAL: 'CHA',
  T_CONSUMPTION: 'CON',
  T_WEB: 'WEB',
  T_ACC: 'ACC',
  T_FEA: 'FEA',
  T_SUGGESTION: 'SUG',
  T_OTHER: 'OTH',
  T_INVOICE: 'INV',
  T_INVOICE_INTEGRATION: 'INT',
  T_NEW_CONTRACT: 'NCT',
  T_PROF_ADD_EMAIL: 'ADD',
  T_PROF_REM_EMAIL: 'REM',
  T_PROF_CH_PAYMENT: 'PCA',
  T_CH_CONTRACT_ADMIN: 'CAD',
}
export const INTEGRATION_TYPES = {
  T_CON: 'CON',
  T_IES: 'IES',
  T_TAAF: 'TAAF',
  T_EMAIL: 'EMAIL',
}
export const INTEGRATION_OPTIONS = [
  {
    id: INTEGRATION_TYPES.T_CON,
    text: 'Conecta'
  },
  {
    id: INTEGRATION_TYPES.T_IES,
    text: 'IESA/TuComunidad.com'
  },
  {
    id: INTEGRATION_TYPES.T_TAAF,
    text: 'TAAF'
  },
  {
    id: INTEGRATION_TYPES.T_EMAIL,
    text: 'Recibir facturas en PDF por e-mail'
  }
]
export const FREQUENCY_INTEGRATION = [
  {
    id: 'DLY',
    text: 'Diaria'
  },
  {
    id: 'WLY',
    text: 'Semanal'
  },
  {
    id: 'MLY',
    text: 'Mensual'
  }
]
export const RATES = [
  {
    id: 'T20TD',
    text: 'Tarifa 2.0TD',
    range: [0.001, 15],
    power: {
      p1: true,
      p2: true,
      p3: false,
      p4: false,
      p5: false,
      p6: false,
    },
    periods: [true, true, false, false, false, false]
  },
  {
    id: 'T30TD',
    text: 'Tarifa 3.0TD',
    range: [15.001, 450],
    power: {
      p1: true,
      p2: true,
      p3: true,
      p4: true,
      p5: true,
      p6: true,
    },
    periods: [true, true, true, true, true, true]
  },
  {
    id: 'T6XTD',
    text: 'Alta tensión (+450 kW)',
    range: [450.001, null],
    power: {
      p1: true,
      p2: true,
      p3: true,
      p4: true,
      p5: true,
      p6: true,
    },
    periods: [true, true, true, true, true, true]
  }
]

export const breakpoints = {
  gap,
  tablet,
  desktop,
  widescreen,
  fullhd
}
