
export default {
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    const { id } = this.$route.params
    this.$http.verifyToken().then((tokenValid) => {
      if (tokenValid) {
        const token = this.$http.getToken()
        const url = `${process.env.VUE_APP_API_URL}/api/1.0/renewals/${id}/download?token=${token}`
        window.location = url
        setTimeout(() => { window.close() }, 2000)
      } else {
        this.$router.push({ name: 'LoginView', query: { next: this.$route.path } }, () => {})
      }
    });
  },
  methods: {
    close() {
      window.close()
    }
  }
}
