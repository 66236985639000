import { mapState } from 'vuex'
import {
  Badge,
  BadgeDropdown,
  ListView,
  Select as MSelect,
  SearchBox,
  SectionHeader,
  VueModal,
  CheckedList,
  MiniCard
} from '@/shared/components'

import { UIStatus, Pagination } from '@/shared/mixins'

import {
  STORE_SET_FILES,
  STORE_CLEAR_FILES
} from '@/clients/clientsConstants'

export default {
  components: {
    Badge,
    ListView,
    MSelect,
    SectionHeader,
    SearchBox,
    BadgeDropdown,
    VueModal,
    CheckedList,
    MiniCard
  },
  mixins: [UIStatus, Pagination],
  data() {
    return {
      checkedFiles: [],
      showDownloadModal: false,
      buttons: [{ title: 'Descargar', method: 'asdf' }],
      filters: {
        status: '',
        ordering: '-id'
      },
      statuses: [
        { id: '', text: this.$t('anyone'), value: null },
        { id: 'PEN', text: this.$t('pending'), value: 'False' },
        { id: 'DWN', text: this.$t('downloaded'), value: 'True' },
      ]
    }
  },
  computed: mapState({
    files: state => state.clients.files.results,
    total: state => state.clients.files.count,
    currentPage: state => state.clients.files.page,
    isEmpty: state => state.clients.files.results.length === 0,
    hasNextPage: state => state.clients.files.next !== null,
    globalFilters: state => state.clients.globalFilters,
    disableScroll() { return !(this.hasNextPage) },
  }),
  created() {
    this.setBlank()
    this.$store.dispatch(STORE_CLEAR_FILES)
    this.fetchFiles()
  },
  methods: {
    async fetchFiles() {
      try {
        this.setLoading()
        const params = this.removeEmptyProperties({
          ...this.filters, page: this.page, ...this.globalFilters
        })
        const results = await this.$http.list('files', params)
        this.$store.dispatch(STORE_SET_FILES, { ...results, page: this.page })
        this.setIdeal()
      } catch (error) {
        if (!error.message.isCancelled) this.setError(error.message)
      }
    },
    nextPage() {
      if (!this.hasNextPage || this.loading) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchFiles()
      })
    },
    setCheckedFiles(files) {
      this.checkedFiles = files
    },
    setFilter(filter, value) {
      if (value !== null) {
        this.filters[filter] = value
      } else {
        this.filters = {}
      }
      this.$store.dispatch(STORE_CLEAR_FILES)
      this.page = 1
      this.fetchFiles()
    },
    toggleDownloadModal() {
      this.showDownloadModal = !this.showDownloadModal
    }
  }
}
