export default {
  props: {
    active: Boolean,
    text: {
      type: String,
      default: ''
    },
    closeText: {
      type: String,
      default: 'CERRAR'
    }
  },
  methods: {
    close() {
      this.$emit('close', {})
    }
  }
}
