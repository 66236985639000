import { mapState } from 'vuex'
import {
  Arrow,
  Badge,
  BadgeDropdown,
  ListView,
  Select as MSelect,
  SelectAPI as MSelectApi,
  SearchBox,
  SectionHeader,
  ToastElementsCount,
  Toast
} from '@/shared/components'
import { UIStatus, Pagination } from '@/shared/mixins'
import {
  STORE_SET_INVOICES, STORE_CLEAR_INVOICES, STORE_SET_AGREEMENTS
} from '@/clients/clientsConstants'
import PersistentFilters from '@/shared/mixins/PersistentFilters'
import { downloadExcel, downloadCSV, download } from '@/utils'

export default {
  components: {
    Arrow,
    Badge,
    MSelect,
    MSelectApi,
    ListView,
    SearchBox,
    SectionHeader,
    BadgeDropdown,
    ToastElementsCount,
    Toast,
  },
  mixins: [UIStatus, Pagination, PersistentFilters],
  data() {
    return {
      checkedInvoices: [],
      filtersStorageKey: 'invoices.list',
      filtersConfig: {
        status: '',
        ordering: '-invoiced_date',
        agreement: null
      },
      buttons: [
        { title: 'Descargar', method: this.download }
      ],
      statuses: [
        { id: '', text: this.$t('anyone') },
        { id: 'PAI', text: this.$t('paid') },
        { id: 'UNP', text: this.$t('unpaid') },
        { id: 'INV', text: this.$t('invoiced') },
      ],
      searchOptionsConfig: [
        {
          id: 'idn',
          text: this.$t('invoice')
        },
        {
          id: 'contract_id',
          text: this.$t('contract')
        },
        {
          id: 'cups',
          text: this.$t('cups')
        },
        {
          id: 'vat_id',
          text: this.$t('nif')
        },
      ],
      queryFilter: {},
      snackBarMessage: null,
      showCheckedInvoiceBool: false,
      invoiceHasNextPage: false,
      hasCheckAll: false,
      hasFinishLoadDownload: false,
      downloadType: '',
      isDownloadBool: false,
      downloadedInvoices: 0,
      showIsDownloading: false
    }
  },
  computed: mapState({
    agreements(state) {
      return state.clients.agreements.results.map(a => ({
        id: a.code,
        text: a.code ? a.code : this.$t('anyone')
      }))
    },
    invoices: state => state.clients.invoices.results || [],
    total: state => state.clients.invoices.count || 0,
    isEmpty: (state) => {
      const clients = state.clients || {}
      const invoices = clients.invoices || {}
      const results = invoices.results || {}
      const length = results.length || 0
      return length === 0
    },
    hasNextPage: state => state.clients.invoices.next != null,
    globalFilters: state => state.clients.globalFilters,
    snackBarActive() { return this.snackBarMessage !== null },
    invoiceLoadCount: (state) => {
      const clients = state.clients || {}
      const invoices = clients.invoices || {}
      const results = invoices.results || {}
      return results.length || 0
    },
    showCheckedInvoice() { return this.checkedInvoices.length !== 0 },
    numCheckedInvoice() { return this.checkedInvoices.length },
    numDownloadedInvoices() { return this.downloadedInvoices },
    disableScroll() { return !(this.hasNextPage) },
    showIsDownload() { return this.isDownloadBool }
  }),
  created() {
    this.setBlank()
    this.loadFilters()
    this.$store.dispatch(STORE_CLEAR_INVOICES)
    this.fetchInvoices()
  },
  watch: {
    showCheckedInvoice() {
      this.showCheckedInvoiceBool = true
    },
    invoiceHasNextPage() {
      if (this.invoiceHasNextPage) {
        this.nextPage(this.hasCheckAll)
      }
    },
    hasFinishLoadDownload() {
      if (this.hasFinishLoadDownload) {
        this.isDownloadBool = false
        this.downloadFile(this.downloadType)
      }
    },
    showIsDownload() {
      if (!this.hasFinishLoadDownload) {
        this.isDownloadBool = true
      } else {
        this.isDownloadBool = false
      }
    },
  },
  methods: {
    getDropdownMessage() {
      switch (this.filters.status) {
        case 'PAI':
          return this.$t('paid')
        case 'UNP':
          return this.$t('unpaid')
        case 'INV':
          return this.$t('invoiced')
        default:
          return this.$t('anyStatus')
      }
    },
    async fetchInvoices(downloadFile = false) {
      try {
        if (downloadFile) {
          this.invoiceHasNextPage = false
        }
        this.setLoading()
        const params = this.removeEmptyProperties({
          ...this.filters, page: this.page, ...this.globalFilters
        })
        const results = await this.$http.list('invoices', params)
        this.$store.dispatch(STORE_SET_INVOICES, { ...results, page: this.page })
        if (downloadFile) {
          if (results.results.length !== 0) {
            results.results.forEach(element => this.checkedInvoices.push(element))
          }
          if (results.next !== null) {
            this.invoiceHasNextPage = true
            this.hasFinishLoadDownload = false
          } else {
            this.invoiceHasNextPage = false
            this.hasFinishLoadDownload = true
          }
        }
        this.setIdeal()
      } catch (error) {
        if (!error.message.isCancelled) {
          this.error = error.message
          this.setError(error)
        }
      }
    },
    splitInvoices(ids) {
      const result = ids.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 10)
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
      return result
    },
    async buildPdfZipfile(ids, filename) {
      const zip = await this.$http.downloadInvoicesPDF(ids)
      try {
        download(zip, filename)
      } catch (e) {
        this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
      }
    },
    async downloadPdfFiles(invoices, ids) {
      // eslint-disable-next-line
      let filename = ''
      if (ids.length === 1) {
        filename = `${invoices[0].contract}_${invoices[0].idn}.pdf`
      } else {
        filename = 'facturas-'
      }
      try {
        if (this.checkedInvoices.length > 20) {
          this.showIsDownloading = true
          this.showCheckedInvoiceBool = false
          const result = this.splitInvoices(ids)
          let initialCount = 1
          let finalCount = 0
          // eslint-disable-next-line no-restricted-syntax
          for (const element of result) {
            finalCount += element.length
            const newFilename = filename.concat(initialCount.toString(), '-', finalCount.toString(), '.zip')
            initialCount += element.length
            // eslint-disable-next-line no-await-in-loop
            await this.buildPdfZipfile(element, newFilename)
            this.downloadedInvoices = finalCount
          }
          this.showIsDownloading = true
        } else {
          await this.buildPdfZipfile(ids, filename)
          this.snackBarMessage = `${this.$t('downloadingFile')}`
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error', error)
        this.setError('error', error.message)
      }
    },
    async downloadFile(type) {
      const invoices = this.checkedInvoices.map((invoice) => {
        // eslint-disable-next-line
        invoice.start_date = invoice.start_date.split('T')[0]
        // eslint-disable-next-line
        invoice.finish_date = invoice.finish_date.split('T')[0]
        return invoice
      })
      if (type === 'xls') {
        this.snackBarMessage = `${this.$t('downloadingFile')}`
        try {
          downloadExcel(invoices, `${this.$t('invoices')}.xls`, {
            contract: 'Número de contrato',
            cups: 'CUPS',
            idn: 'Número de factura',
            customer_vat_id: 'NIF',
            total: 'Total',
            base_total: 'Base Imponible',
            tax_total: 'IVA',
            start_date: 'Fecha Inicio Facturación',
            finish_date: 'Fecha Fin Facturación'
          })
        } catch (e) {
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        }
      } else if (type === 'csv') {
        this.snackBarMessage = `${this.$t('downloadingFile')}`
        try {
          downloadCSV(invoices, `${this.$t('invoices')}.csv`, {
            contract: 'Número de contrato',
            cups: 'CUPS',
            idn: 'Número de factura',
            customer_vat_id: 'NIF',
            total: 'Total',
            base_total: 'Base Imponible',
            tax_total: 'IVA',
            start_date: 'Fecha Inicio Facturación',
            finish_date: 'Fecha Fin Facturación'
          })
        } catch (e) {
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        }
      } else if (type === 'pdf') {
        const ids = this.checkedInvoices.map(i => i.id)
        if (ids.length > 0) {
          await this.downloadPdfFiles(invoices, ids)
        }
      }
      this.showCheckedInvoiceBool = false
    },
    async download(type) {
      this.downloadType = type
      this.isDownloadBool = false
      if (this.hasCheckAll) {
        if (!this.hasNextPage) {
          await this.downloadFile(type)
        } else {
          this.nextPage(true)
        }
      } else if (this.checkedInvoices.length > 0) {
        await this.downloadFile(type)
      }
      this.isDownloadBool = null
    },
    closeSnackBar() {
      this.snackBarMessage = null
    },
    nextPage(downloadFile = false) {
      if (this.loading || !this.hasNextPage) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchInvoices(downloadFile)
      })
    },
    setCheckedInvoices(invoices) {
      this.checkedInvoices = invoices
    },
    checkAll(checkAll) {
      this.hasCheckAll = checkAll
    },
    setQueryFilter(filter, value) {
      this.filters = {
        invoiced_date_from: this.filters.invoiced_date_from,
        invoiced_date_to: this.filters.invoiced_date_to,
        status: this.filters.status,
        agreement: this.filters.agreement,
        [filter]: value
      }
      this.$store.dispatch(STORE_CLEAR_INVOICES)
      this.page = 1
      this.fetchInvoices()
    },
    setFilter(filter, value) {
      this.filters[filter] = value
      this.saveFilters()
      this.$store.dispatch(STORE_CLEAR_INVOICES)
      this.page = 1
      this.fetchInvoices()
    },
    setOrdering() {
      this.setFilters(this.filters)
    },
    removeFilters() {
      this.cleanFilters()
      this.$store.dispatch(STORE_CLEAR_INVOICES)
      this.fetchInvoices()
    },
    agreementsModifier(response) {
      if (response.count > 0) this.$store.dispatch(STORE_SET_AGREEMENTS, response)
    }
  }
}
