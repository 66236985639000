import {
  required, email, requiredIf, alphaNum
} from 'vuelidate/lib/validators'
import { isValid as validIBAN } from 'iban'
import {
  Checkbox,
  SearchAPI as SearchApi,
  BankAccountInput,
  DuplicatedTickets
} from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import { Form, UIStatus } from '@/shared/mixins'

const optionalEmail = value => value === '' || email(value)

export default {
  mixins: [Form, UIStatus],
  components: {
    Checkbox,
    SearchApi,
    BankAccountInput,
    DuplicatedTickets
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    postalDataChangeChecked() {
      return this.updatePostalAddress || false
    },
    paymentDataChangeChecked() {
      return this.requestPaymentDataChange || false
    },
    ibanValidator() {
      if (this.requestPaymentDataChange) {
        return !validIBAN(this.bankAccountParams) || false
      }
      return this.requestPaymentDataChange || false
    },
    hasOpenTicket() {
      return this.duplicateTickets.length > 0
    },
  },
  validations: {
    contract: { required },
    currentAgreement: { },
    newAgreement: { },
    email: { email: optionalEmail },
    phone: { },
    mobilePhone: { },
    fax: { },
    // Postal
    updatePostalAddress: {},
    name: { required: requiredIf('postalDataChangeChecked') },
    address: { required: requiredIf('postalDataChangeChecked') },
    zip: { required: requiredIf('postalDataChangeChecked') },
    city: { required: requiredIf('postalDataChangeChecked') },
    state: { required: requiredIf('postalDataChangeChecked') },
    // Payment
    requestPaymentDataChange: {},
    paymentName: { required: requiredIf('paymentDataChangeChecked') },
    paymentNif: { required: requiredIf('paymentDataChangeChecked'), alphaNum },
    paymentAddress: { },
    paymentState: { },
    paymentCity: { },
    paymentZip: { },
    bankAccountParams: { required: requiredIf('ibanValidator') }
  },
  data() {
    return {
      contract: this.selected ? this.selected : {},
      name: this.params ? this.params.name : '',
      address: this.params ? this.params.address : '',
      zip: this.params ? this.params.zip : '',
      city: this.params ? this.params.city : '',
      state: this.params ? this.params.state : '',
      phone: this.params ? this.params.phone : '',
      mobilePhone: this.params ? this.params.mobilePhone : '',
      fax: this.params ? this.params.fax : '',
      email: this.params ? this.params.email : '',
      comments: '',
      paymentName: '',
      paymentNif: '',
      paymentAddress: '',
      paymentState: '',
      paymentCity: '',
      paymentZip: '',
      bankAccountParams: '',
      updatePostalAddress: this.params ? this.params.updatePostalAddress : false,
      requestPaymentDataChange: this.params ? this.params.requestPaymentDataChange : '',
      acceptConditions: true,
      keepData: false,
      newAgreement: this.params ? this.params.newAgreement : '',
      currentAgreement: this.params ? this.params.currentAgreement : '',
      agreementManage: false
    }
  },
  methods: {
    postTicket() {
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.changeAdmin({
        contract: this.contract,
        newAgreement: this.newAgreement ? this.newAgreement.code : this.newAgreement,
        currentAgreement: this.currentAgreement ? this.currentAgreement.code : this.currentAgreement,
        email: this.email ? this.email : '',
        phone: this.phone ? this.phone : '',
        mobilePhone: this.mobilePhone ? this.mobilePhone : '',
        fax: this.fax ? this.fax : '',
        name: this.name ? this.name : '',
        address: this.address ? this.address : '',
        zip: this.zip ? this.zip : '',
        city: this.city ? this.city : '',
        state: this.state ? this.state : '',
        paymentName: this.paymentName ? this.paymentName : '',
        paymentNif: this.paymentNif ? this.paymentNif : '',
        paymentAddress: this.paymentAddress ? this.paymentAddress : '',
        paymentState: this.paymentState ? this.paymentState : '',
        paymentCity: this.paymentCity ? this.paymentCity : '',
        paymentZip: this.paymentZip ? this.paymentZip : '',
        iban: this.bankAccountParams.substring(0, 4),
        accountEntity: this.bankAccountParams.substring(4, 8),
        office: this.bankAccountParams.substring(8, 12),
        dc: this.bankAccountParams.substring(12, 14),
        account: this.bankAccountParams.substring(14, 24),
        comments: this.comments,
        updatePostalAddress: this.updatePostalAddress,
        requestPaymentDataChange: this.requestPaymentDataChange,
        keepData: this.keepData,
      }, this.type, user)
      this.$emit('send', ticket)
      if (!this.keepData) {
        this.reset()
      }
    },
    reset() {
      this.contract = {}
      this.name = ''
      this.address = ''
      this.zip = ''
      this.city = ''
      this.state = ''
      this.phone = ''
      this.mobilePhone = ''
      this.fax = ''
      this.email = ''
      this.comments = ''
      this.paymentName = ''
      this.paymentNif = ''
      this.paymentAddress = ''
      this.paymentState = ''
      this.paymentCity = ''
      this.paymentZip = ''
      this.bankAccountParams = ''
      this.updatePostalAddress = false
      this.requestPaymentDataChange = false
      this.keepData = ''
      this.newAgreement = null
      this.currentAgreement = null
      this.$v.$reset()
    },
    async handleContractChange(newData) {
      if (newData != null) {
        const contract_id = newData.iid
        if (this.currentAgreement === undefined || this.currentAgreement === null) {
          try {
            const agreement = await this.$http.getContractAgreement(contract_id)
            this.currentAgreement = agreement.agreement
            this.agreementManage = agreement.agreement_manages
          } catch (error) {
            this.setError('Contract without agreement')
          }
        }
      }
      return newData
    }
  },
  watch: {
    contract: {
      handler: 'handleContractChange',
      deep: true
    }
  },
}
