import html2pdf from 'html2pdf.js'
import {
  Card,
  MiniCardList,
  MiniCard,
  SectionHeaderTag,
  SectionHeader,
  MessageBox,
  Loading
} from '@/shared/components'
import Pagination from '@/shared/mixins/Pagination'
import UIStatus from '@/shared/mixins/UIStatus'

import {
  STORE_CLEAR_TICKETS
} from '@/clients/clientsConstants'
import { TICKET_STATUSES } from '@/config'

export default {
  components: {
    Card,
    MiniCardList,
    MiniCard,
    SectionHeader,
    MessageBox,
    Loading,
    SectionHeaderTag
  },
  mixins: [UIStatus, Pagination],
  data() {
    return {
      ticket: {
        status: '',
        comments: []
      },
      realInvoice: null,
      chatMessage: '',
      downloadActive: false,
      showAttachMessage: false,
      isAttaching: false,
      showUndo: false,
      addedCommentId: null,
      TICKET_STATUSES
    }
  },
  computed: {
    invoice() {
      return this.realInvoice ? this.realInvoice.idn : null
    },
    tag() {
      if (!this.ticket) return {}
      const status = this.getStatus(this.ticket.status)
      return {
        text: status.text,
        icon: 'file-contract',
        color: status.color
      }
    },
    title() {
      return `${this.$t('ticket')} #${this.$route.params.id}`
    },
    nextStatus() {
      const { status } = this.ticket
      switch (status) {
        case TICKET_STATUSES.opened:
          return TICKET_STATUSES.inProcess
        case TICKET_STATUSES.inProcess:
          return TICKET_STATUSES.closed
        default:
          return TICKET_STATUSES.opened
      }
    },
    nextStatusText() {
      const { status } = this.ticket
      switch (status) {
        case TICKET_STATUSES.opened:
          return this.$t('setInProcess')
        case TICKET_STATUSES.inProcess:
          return this.$t('closeTicket')
        default:
          return this.$t('reopenTicket')
      }
    },
    nextIcon() {
      const { status } = this.ticket
      switch (status) {
        case TICKET_STATUSES.opened:
          return 'arrow-right'
        case TICKET_STATUSES.inProcess:
          return 'times'
        default:
          return 'redo'
      }
    },
    isStaff() {
      const user = this.$store.state.auth.user || {}
      return user.is_staff || false
    }
  },
  created() {
    this.setBlank()
    this.$store.dispatch(STORE_CLEAR_TICKETS)
    this.fetchTicket()
  },
  methods: {
    async fetchTicket() {
      try {
        this.setLoading()
        const result = await this.$http.get('tickets', this.$route.params.id)
        const { comments, ...rest } = result
        this.ticket = {
          ...rest,
          comments: comments.reverse()
        }
        this.setIdeal()
        this.loadInvoiceData()
      } catch (error) {
        this.setError(error.message)
        if (error.response && error.response.status === 404) {
          this.$router.push({ name: 'NOT_FOUND_404' }, () => {})
        }
      }
    },
    async undoComment() {
      try {
        this.$http.deleteComment(this.addedCommentId)
        this.addedCommentId = null
        this.ticket.comments = this.ticket.comments.slice(0, this.ticket.comments.length - 1)
      } catch (error) {
        this.setError(error.message)
      }
      this.showUndo = false
    },
    async postComment() {
      const { id } = this.$route.params
      this.showUndo = false

      try {
        const comment = {
          ticket: id,
          message: this.chatMessage
        }
        const response = await this.$http.postComment(id, comment)
        const { username, first_name, last_name } = this.$store.state.auth.user
        this.ticket.comments.push({
          ...response,
          user: {
            username,
            first_name,
            last_name,
          }
        })
        this.addedCommentId = response.id
        this.showUndo = true
        setTimeout(() => { this.showUndo = false }, 45000)
        this.showAttachMessage = false
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
        this.setError(error.message)
      }
    },
    async loadInvoiceData() {
      if (this.ticket.invoice_id) {
        try {
          this.realInvoice = await this.$http.get('invoices', this.ticket.invoice_id)
        } catch (error) {
          this.realInvoice = null
          // eslint-disable-next-line no-console
          console.log('error', error.message)
        }
      }
    },
    pushToContractView() {
      this.$router.push({ name: 'ClientContractView', params: { id: this.ticket.contract_id } }, () => {})
    },
    pushToInvoiceView() {
      this.$router.push({ name: 'ClientInvoiceView', params: { id: this.ticket.invoice_id } }, () => {})
    },
    closeSnackbar() {
      this.downloadActive = false
    },
    downloadFile() {
      this.downloadActive = true
      const name = `ticket_${this.$route.params.id}.pdf`
      html2pdf().from(this.$refs.pdfFile).save(name)
      this.downloadActive = false
    },
    async uploadFiles(files) {
      const promises = []
      this.setLoading()
      files.forEach((file) => {
        promises.push(this.uploadFile(file))
      })
      await Promise.all(promises)
      this.setIdeal()
    },
    async uploadFile(file) {
      const { id } = this.$route.params
      this.showUndo = false
      try {
        this.showAttachMessage = true
        this.isAttaching = true
        const response = await this.$http.uploadTicketFile(id, file, false)
        this.ticket.comments = [...this.ticket.comments,
          {
            message: response.url,
            user: { username: this.$store.state.auth.user.username },
            creation_date: new Date()
          }
        ]
        this.isAttaching = false
        this.setIdeal()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    async removeMsg(value) {
      try {
        await this.$http.deleteComment(value)
        this.fetchTicket()
      } catch (error) {
        this.setError(error.message)
      }
    },
    async changeTicketStatus() {
      const { id } = this.$route.params
      this.showUndo = false

      try {
        const ticket = await this.$http.put('tickets', id, {
          ...this.ticket,
          status: this.nextStatus
        })
        this.ticket = ticket
        this.ticket.comments = this.ticket.comments.reverse()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    }
  }
}
