import LinksReplacer from '@/shared/mixins/LinksReplacer'
import UIStatus from '@/shared/mixins/UIStatus'

export default {
  mixins: [LinksReplacer, UIStatus],
  props: {
    message: {
      type: String,
      default: ''
    },
    receives: {
      type: Boolean,
      default: false
    },
    readable: {
      type: Boolean,
      default: false
    },
    isRead: {
      type: Boolean,
      default: false
    },
    sender: {
      type: Object,
      required: true
    },
    creationDate: {
      type: String,
      default: ''
    },
    messageId: {
      type: Number,
      default: null
    },
    isErasable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    senderUsername() {
      const authUser = this.$store.state.auth.user || {}
      const isStaff = authUser.is_staff || false
      return isStaff ? `(${this.sender.username})` : ''
    }
  },
  methods: {
    async removeMessage(id) {
      this.$emit('removeMsg', id)
    }
  }
}
