export default {
  props: {
    color: {
      type: String,
      default: '#909090',
    },
    weight: {
      type: Number,
      default: 4,
    },
    direction: {
      type: String,
      validator: value => ['top', 'right', 'bottom', 'left'].indexOf(value) > -1,
      default: 'left',
    },
    width: {
      type: Number,
      default: 8,
    },
    height: {
      type: Number,
      default: 8,
    },
  },
}
