import { required } from 'vuelidate/lib/validators'
import SearchApi from '@/shared/components/SearchAPI'
import { Checkbox, DuplicatedTickets } from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import { Form } from '@/shared/mixins'

export default {
  mixins: [Form],
  components: { Checkbox, SearchApi, DuplicatedTickets },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      default: () => {}
    }
  },
  validations: {
    contract: { required },
    name: { required },
    address: { required },
    zip: { required },
    city: { required },
    state: { required }
  },
  data() {
    return {
      contract: this.selected ? this.selected : {},
      name: this.params ? this.params.name : '',
      address: this.params ? this.params.address : '',
      zip: this.params ? this.params.zip : '',
      city: this.params ? this.params.city : '',
      state: this.params ? this.params.state : '',
      comments: '',
      keepData: false
    }
  },
  methods: {
    postTicket() {
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.changeAddress({
        contract: this.contract,
        name: this.name,
        address: this.address,
        zip: this.zip,
        city: this.city,
        state: this.state,
        comments: this.comments,
        keepData: this.keepData
      }, this.type, user)
      this.$emit('send', ticket)
      if (!this.keepData) {
        this.reset()
      }
    },
    reset() {
      this.contract = {}
      this.name = ''
      this.address = ''
      this.zip = ''
      this.city = ''
      this.state = ''
      this.comments = ''
      this.$v.$reset()
    },
    copyData() {
      this.name = this.contract.fullname
      this.address = this.contract.address
      this.zip = this.contract.zip
      this.city = this.contract.city
      this.state = this.contract.state
    }
  }
}
