<template>
  <div />
</template>

<script>
import { isAuthenticated, isMiddleman, isCustomer } from '@/utils'

export default {
  beforeCreate() {
    if (!isAuthenticated()) {
      this.$router.push({ name: 'LoginView', query: { next: this.$route.path } }, () => {})
    } else if (isCustomer()) {
      this.$router.push({ name: 'ClientsSTART' }, () => {})
    } else if (isMiddleman()) {
      this.$router.push({ name: 'ProfessionalsSTART' }, () => {})
    } else {
      this.$router.push({ name: 'Client404NotFound' }, () => {})
    }
  }
}
</script>
