export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    tag: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
}
