export default {
  props: {
    bigTitle: {
      type: Boolean,
      default: false
    },
    titleLeft: {
      type: [String, Number],
      default: ''
    },
    titleRight: {
      type: [String, Number],
      default: ''
    },
    leftText: {
      type: [String, Number],
      default: ''
    },
    rightText: {
      type: [String, Number],
      default: ''
    },
    actionLeftText: {
      type: Function,
      required: false,
      default: undefined
    },
    actionRightText: {
      type: Function,
      required: false,
      default: undefined
    },
    separator: {
      type: String,
      default: ''
    }
  }
}
