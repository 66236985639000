import {
  PATH_CLIENT_MAIN,
  PATH_CLIENT_CONTRACTS_LIST,
  PATH_CLIENT_CONTRACT,
  PATH_CLIENT_INVOICES_LIST,
  PATH_CLIENT_INVOICE,
  PATH_CLIENT_INVOICE_LINK,
  PATH_CLIENT_TICKETS_LIST,
  PATH_CLIENT_TICKET,
  PATH_CLIENT_TICKET_NEW,
  PATH_CLIENT_MESSAGES_LIST,
  PATH_CLIENT_FILES_LIST,
  PATH_CLIENT_ACCOUNT,
  PATH_CLIENT_404,
  PATH_CLIENT_FILES_DOWNLOAD,
  PATH_CLIENT_RENEWAL_DOWNLOAD
} from '@/clients/clientsConstants'

import AccountView from '@/clients/views/ClientAccountView'
import ClientView from '@/clients/views/ClientView'
import ContractView from '@/clients/views/ClientContractView'
import ContractsListView from '@/clients/views/ClientContractsListView'
import FilesListView from '@/clients/views/ClientFilesListView'
import ClientDownloadFileView from '@/clients/views/ClientDownloadFileView'
import ClientDownloadRenewalView from '@/clients/views/ClientDownloadRenewalView'
import InvoiceView from '@/clients/views/ClientInvoiceView'
import InvoiceRedirectView from '@/clients/views/ClientInvoiceRedirectView'
import InvoicesListView from '@/clients/views/ClientInvoicesListView'
import MessagesListView from '@/clients/views/ClientMessagesListView'
import TicketView from '@/clients/views/ClientTicketView'
import TicketCreateView from '@/clients/views/ClientTicketCreateView'
import TicketsListView from '@/clients/views/ClientTicketsListView'
import NotFound404 from '@/shared/views/NotFound404/NotFound404.vue'

import { isCustomer } from '@/utils'

function clientGuard(to, from, next) {
  if (isCustomer()) {
    next()
  } else {
    next({ name: 'LoginView', query: { next: to.path } })
  }
}

export default [{
  path: PATH_CLIENT_MAIN,
  component: ClientView,
  children: [
    {
      path: '/',
      redirect: PATH_CLIENT_CONTRACTS_LIST,
      name: 'ClientsSTART',
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_ACCOUNT,
      name: 'ClientAccountView',
      component: AccountView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_CONTRACTS_LIST,
      name: 'ClientContractsListView',
      component: ContractsListView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_CONTRACT,
      name: 'ClientContractView',
      component: ContractView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_FILES_DOWNLOAD,
      name: 'ClientDownloadFileView',
      component: ClientDownloadFileView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_RENEWAL_DOWNLOAD,
      name: 'ClientDownloadRenewalView',
      component: ClientDownloadRenewalView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_FILES_LIST,
      name: 'ClientFilesListView',
      component: FilesListView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_INVOICES_LIST,
      name: 'ClientInvoicesListView',
      component: InvoicesListView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_INVOICE,
      name: 'ClientInvoiceView',
      component: InvoiceView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_INVOICE_LINK,
      name: 'ClientInvoiceRedirectView',
      component: InvoiceRedirectView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_MESSAGES_LIST,
      name: 'ClientMessagesListView',
      component: MessagesListView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_TICKETS_LIST,
      name: 'ClientTicketsListView',
      component: TicketsListView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_TICKET,
      name: 'ClientTicketView',
      component: TicketView,
      props: true,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_TICKET_NEW,
      name: 'ClientTicketCreateView',
      component: TicketCreateView,
      meta: {
        protected: true
      },
      beforeEnter: clientGuard
    },
    {
      path: PATH_CLIENT_404,
      name: 'Client404NotFound',
      component: NotFound404,
      meta: {
        protected: true
      }
    }
  ]
}]
