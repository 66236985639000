// STORE CONSTANTS FOR CLIENTS

export const STORE_CHANGE_CLIENTS_SECTION_TITLE = 'CHANGE_CLIENTS_SECTION_TITLE'
export const STORE_SET_AGREEMENTS = 'SET_AGREEMENTS'
export const STORE_SET_CONTRACTS = 'STORE_SET_CONTRACTS'
export const STORE_CLEAR_CONTRACTS = 'STORE_CLEAR_CONTRACTS'
export const STORE_CHECK_CONTRACT = 'STORE_CHECK_CONTRACT'
export const STORE_CLEAR_CHECKED_CONTRACTS = 'STORE_CLEAR_CHECKED_CONTRACTS'
export const STORE_SET_INVOICES = 'STORE_SET_INVOICES'
export const STORE_CLEAR_INVOICES = 'STORE_CLEAR_INVOICES'
export const STORE_CHECK_INVOICE = 'STORE_CHECK_INVOICE'
export const STORE_SET_EMAIL_FILTER = 'STORE_SET_EMAIL_FILTER'
export const STORE_SET_TICKETS = 'STORE_SET_TICKETS'
export const STORE_CLEAR_TICKETS = 'STORE_CLEAR_TICKETS'
export const STORE_CLEAR_FILES = 'STORE_CLEAR_FILES'
export const STORE_SET_FILES = 'STORE_SET_FILES'
export const STORE_CLEAR_MESSAGES = 'STORE_CLEAR_MESSAGES'
export const STORE_SET_MESSAGES = 'STORE_SET_MESSAGES'
export const STORE_CLEAR_AGREEMENTS = 'STORE_CLEAR_AGREEMENTS'


// ROUTER PATH CONSTANTS FOR CLIENTS

export const PATH_CLIENT_MAIN = '/customer'
export const PATH_CLIENT_CONTRACTS_LIST = `${PATH_CLIENT_MAIN}/contracts`
export const PATH_CLIENT_CONTRACT = `${PATH_CLIENT_MAIN}/contract/:id`
export const PATH_CLIENT_INVOICES_LIST = `${PATH_CLIENT_MAIN}/invoices`
export const PATH_CLIENT_INVOICE = `${PATH_CLIENT_MAIN}/invoice/:id`
export const PATH_CLIENT_INVOICE_LINK = `${PATH_CLIENT_MAIN}/invoice/link/:idn`
export const PATH_CLIENT_TICKETS_LIST = `${PATH_CLIENT_MAIN}/tickets`
export const PATH_CLIENT_TICKET = `${PATH_CLIENT_MAIN}/ticket/:id`
export const PATH_CLIENT_TICKET_NEW = `${PATH_CLIENT_MAIN}/tickets/new`
export const PATH_CLIENT_MESSAGES_LIST = `${PATH_CLIENT_MAIN}/messages`
export const PATH_CLIENT_MESSAGE = `${PATH_CLIENT_MAIN}/message/:id`
export const PATH_CLIENT_FILES_DOWNLOAD = `${PATH_CLIENT_MAIN}/files/download/:id`
export const PATH_CLIENT_RENEWAL_DOWNLOAD = `${PATH_CLIENT_MAIN}/renewals/:id/download`
export const PATH_CLIENT_FILES_LIST = `${PATH_CLIENT_MAIN}/files`
export const PATH_CLIENT_ACCOUNT = `${PATH_CLIENT_MAIN}/account`
export const PATH_CLIENT_404 = '*'
