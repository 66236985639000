import { required } from 'vuelidate/lib/validators'
import { SearchAPI as SearchApi, DuplicatedTickets } from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import { Form } from '@/shared/mixins'

export default {
  mixins: [Form],
  components: {
    SearchApi,
    DuplicatedTickets
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
      default: ''
    },
    selected: {
      type: Object,
      default: undefined
    }
  },
  validations: {
    invoice: { required },
    comments: { required }
  },
  data() {
    return {
      invoice: this.selected ? this.selected : {},
      query: {},
      comments: '',
    }
  },
  methods: {
    postTicket() {
      const { user } = this.$store.state.auth
      const invoice = this.invoice || {}
      const ticket = TicketGenerator.invoiceIssue({
        invoice: invoice.id ? invoice.id : invoice,
        contract: this.invoice.contract,
        comments: this.comments,
        idn: this.invoice.idn
      }, this.type, user)
      this.$emit('send', ticket)
      this.reset()
    },
    reset() {
      this.invoice = {}
      this.comments = ''
      this.$v.$reset()
    },
    updateQuery() {
      this.query = { invoice_id: this.invoice }
    }
  }
}
