/* eslint-disable no-unused-vars */
import infiniteScroll from 'vue-infinite-scroll'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import Vue from '@/vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
// import '@/registerServiceWorker'
import i18n from './i18n'
import { SENTRY_URL } from './config'
import pack from '../package.json'

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: process.env.NODE_ENV,
    release: `${pack.name}@${pack.version}`,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true })
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'There is no clipping info for given tab',
      'undefined is not an object (evaluating \'webkit.messageHandlers.loginsManagerMessageHandler.postMessage\')',
      'MapsRequestError', 'GEOCODER_GEOCODE: ZERO_RESULTS: No result was found for this GeocoderRequest'
    ]
  });
}

const instance = new Vue({
  router,
  store,
  i18n,
  directives: {
    infiniteScroll
  },
  render: h => h(App)
}).$mount('#app')

/* eslint-disable */
try {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
} catch (e) {}
