import { required, alphaNum } from 'vuelidate/lib/validators'
import { isValid as validIBAN } from 'iban'
import bigInt from 'big-integer'
import {
  Checkbox,
  SearchAPI as SearchApi,
  BankAccountInput,
  DuplicatedTickets
} from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import { Form, UIStatus } from '@/shared/mixins'

export default {
  mixins: [Form, UIStatus],
  components: {
    Checkbox,
    SearchApi,
    BankAccountInput,
    DuplicatedTickets
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: undefined
    },
    type: {
      required: true,
      type: String,
      default: '',
    },
    selected: {
      type: Object,
      default: undefined
    }
  },
  validations: {
    contract: { required },
    name: { required },
    nif: { required, alphaNum },
    address: {},
    city: {},
    state: {},
    zip: {},
    bankAccountParams: { required, validIBAN }
  },
  data() {
    return {
      contract: this.selected ? this.selected : {},
      name: this.params ? this.params.name : '',
      address: this.address ? this.params.address : '',
      zip: this.zip ? this.params.zip : '',
      city: this.city ? this.params.city : '',
      state: this.state ? this.params.state : '',
      nif: this.params ? this.params.nif : '',
      iban: this.params ? this.params.iban : '',
      accountEntity: this.params ? this.params.entity : '',
      office: this.params ? this.params.office : '',
      dc: this.params ? this.params.dc : '',
      account: this.params ? this.params.account : '',
      bankAccountParams: '',
      comments: this.params ? this.params.comments : '',
      keepData: false
    }
  },
  methods: {
    postTicket() {
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.changePaymentData({
        contract: this.contract,
        name: this.name,
        nif: this.nif,
        address: this.address,
        city: this.city,
        state: this.state,
        zip: this.zip,
        iban: this.bankAccountParams.substring(0, 4),
        accountEntity: this.bankAccountParams.substring(4, 8),
        office: this.bankAccountParams.substring(8, 12),
        dc: this.bankAccountParams.substring(12, 14),
        account: this.bankAccountParams.substring(14, 24),
        comments: this.comments,
        keepData: this.keepData
      }, this.type, user)
      if (!ticket.error && !this.keepData) {
        this.reset()
      }
      this.$emit('send', ticket)
    },
    reset() {
      this.contract = {}
      this.name = ''
      this.address = ''
      this.city = ''
      this.state = ''
      this.zip = ''
      this.nif = ''
      this.iban = ''
      this.accountEntity = ''
      this.office = ''
      this.dc = ''
      this.account = ''
      this.bankAccountParams = ''
      this.comments = ''
      this.keepData = false
      this.$v.$reset()
    },
    copyData() {
      this.name = this.contract.fullname
      this.nif = this.contract.customer_vat_id
    },
    nextInput() {
      const {
        office, account, dc
      } = this.$refs
      if (this.accountEntity !== undefined) {
        if (this.accountEntity.length === 4 && this.office === undefined && this.dc === undefined && this.account === undefined) office.focus()
        else if (this.accountEntity.length === 4 && this.office.length === 4 && this.dc === undefined && this.account === undefined) dc.focus()
        else if (this.accountEntity.length === 4 && this.office.length === 4 && this.dc.length === 2 && this.account === undefined) account.focus()
        else if (this.accountEntity.length === 4 && this.office.length === 4 && this.dc.length === 2 && this.account.length === 10 && this.iban === undefined) {
          const a = bigInt(`${this.accountEntity}${this.office}${this.dc}${this.account}142800`)
          const preControlDigit = a.divmod(97).remainder
          const controlDigit = 98 - preControlDigit
          this.iban = `ES${controlDigit < 10 ? `0${controlDigit}` : controlDigit}`
        }
      }
    }
  },
}
