import save from 'file-saver'
import store from '@/store'

export class TimeManager {
  static SECOND = 's'

  static MINUTE = 'min'

  static HOUR = 'h'

  static DAY = 'd'

  /**
   * Adds a determined quantity (minutes, hours, days) to a date and returns the new date
   * @param {Date}    date
   * @param {Number}  quantity
   * @param {String}  timedelta
   *
   * @returns {Date} The updated date
   */
  static add(date, quantity, timedelta = TimeManager.DAY) {
    const ms = date.valueOf()
    let result = ms

    switch (timedelta) {
      case TimeManager.SECOND:
        result += 1000 * quantity
        break
      case TimeManager.MINUTE:
        result += 1000 * 60 * quantity
        break
      case TimeManager.HOUR:
        result += 1000 * 60 * 60 * quantity
        break
      case TimeManager.DAY:
        result += 1000 * 60 * 60 * 24 * quantity
        break
      default:
        break
    }

    return new Date(result)
  }

  static addMonths(isoDate, numberMonths) {
    const dateObject = new Date(isoDate)
    const day = dateObject.getDate(); // returns day of the month number

    // avoid date calculation errors
    dateObject.setHours(20);

    // add months and set date to last day of the correct month
    dateObject.setMonth(dateObject.getMonth() + numberMonths + 1, 0);

    // set day number to min of either the original one or last day of month
    dateObject.setDate(Math.min(day, dateObject.getDate()));

    return dateObject // .toISOString().split('T')[0];
  }

  /**
   * Substracts a determined quantity (minutes, hours, days) to a date and returns the new date
   * @param {Date}    date
   * @param {Number}  quantity
   * @param {String}  timedelta
   *
   * @returns {Date} The updated date
   */
  static substract(date, quantity, timedelta = TimeManager.DAY) {
    return TimeManager.add(date, -quantity, timedelta)
  }

  static toString(date) {
    const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    return `${d}/${m}/${date.getFullYear()}`
  }

  static getMonthYear(date) {
    const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    return `${m}/${date.getFullYear()}`
  }

  static toQueryString(date) {
    const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    return `${date.getFullYear()}-${m}-${d}`
  }

  static fromQueryString(date = '', hour = 0) {
    const [year, month, day] = date.split('-')
    return new Date(year, month - 1, day, hour)
  }

  /**
   * Returns the days between two dates
   * @param {Date} from   Initial date
   * @param {Date} to     Finish date
   */
  static daysBetweenDates(from, to) {
    const parsedFrom = from.valueOf()
    const parsedEnd = to.valueOf()

    const result = Math.round((parsedEnd - parsedFrom) / (1000 * 60 * 60 * 24))
    return result
  }
}

export const formatDate = (date) => {
  const mDate = new Date(date)
  const d = mDate.getDate() < 10 ? `0${mDate.getDate()}` : mDate.getDate()
  const m = mDate.getMonth() + 1 < 10 ? `0${mDate.getMonth() + 1}` : mDate.getMonth() + 1
  return `${d}/${m}/${mDate.getFullYear()}`
}

export const formatHour = (date) => {
  const mDate = new Date(date)
  const h = mDate.getHours()
  const m = mDate.getMinutes()
  return `${(h < 10) ? `0${h}` : h}:${(m < 10) ? `0${m}` : m}`
}

export const formatMoney = money => `${parseFloat(money).toFixed(2).replace('.', ',')}€`

export const queryDate = (date) => {
  const mDate = new Date(date)
  const d = mDate.getDate() < 10 ? `0${mDate.getDate()}` : mDate.getDate()
  const m = mDate.getMonth() + 1 < 10 ? `0${mDate.getMonth() + 1}` : mDate.getMonth() + 1
  return `${mDate.getFullYear()}-${m}-${d}`
}

export const toDateTime = (date) => {
  const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  return `${d}/${m} ${h}:${min}`
}

export const isAuthenticated = () => {
  try {
    const { token } = store.state.auth
    return token !== undefined
  } catch (error) {
    return false
  }
}

export const isCustomer = () => {
  try {
    const { user } = store.state.auth
    return user.is_customer || user.is_staff
  } catch (error) {
    return false
  }
}

export const isUserStaff = () => {
  try {
    const { user } = store.state.auth
    return user.is_staff
  } catch (error) {
    return false
  }
}

export const isMiddleman = () => {
  try {
    const { user } = store.state.auth || {}
    return user.is_middleman || false
  } catch (error) {
    return false
  }
}

export const removeEmptyProperties = (obj) => {
  const nObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      nObj[key] = obj[key]
    }
  })
  return nObj
}

export const mapArrayToObject = (items, key, value) => {
  if (items.length === 0) return undefined
  const nObj = {}
  items.forEach((item) => { nObj[item[key]] = item[value] })
  return nObj
}

export const splitArray = (items, k, v) => {
  if (items.length === 0) return []
  const nArray = []
  items.map(i => nArray.push([i[k], i[v]]))
  return nArray
}

export const downloadBlob = (data, filename) => {
  const blob = new Blob([data], {})
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.id = 'fakeA'
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = filename
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }
}

export const today = () => {
  const now = new Date()
  return formatDate(now)
}

export const sortIcon = (ordering) => {
  switch (ordering) {
    case 'id':
      return 'sort-descending'
    case '-id':
      return 'sort-ascending'
    default:
      return 'sort-descending'
  }
}

export const formatInvoiceLines = (lines) => {
  const beforePx = new RegExp(/^(.*?)(?=P[1-6])/)
  const afterPx = new RegExp(/(P[1-6])(.*)/)
  const parenthesis = new RegExp(/(\(.*\))/)
  const result = []
  const data = {}
  if (lines || false) {
    lines.forEach((line) => {
      const key = line.text.replace(afterPx, '').replace(parenthesis, '')
      if (Object.keys(data).indexOf(key) > -1) {
        data[key].push(line)
      } else {
        data[key] = []
        data[key].push(line)
      }
    })
  }
  Object.keys(data).forEach((k) => {
    result.push({
      header: {
        title: k,
        description: 'Importe'
      },
      lines: data[k].map(i => ({
        ...i,
        text: i.text
          .replace(beforePx, '')
      }))
    })
  })
  return result
}

export function getStatus(status) {
  switch (status) {
    case 'ACT':
      return {
        text: this.$t('active'),
        color: 'has-primary-background',
        borderTop: 'has-primary-border-top-10',
        borderLeft: 'has-primary-border-left-10',
      }
    case 'INA':
      return {
        text: this.$t('inactive'),
        color: 'has-red-background',
        borderTop: 'has-red-border-top-10',
        borderLeft: 'has-red-border-left-10',
      }
    case 'PAI':
      return {
        text: this.$t('paid'),
        color: 'has-primary-background',
        borderTop: 'has-primary-border-top-10',
        borderLeft: 'has-primary-border-left-10',
      }
    case 'UNP':
      return {
        text: this.$t('unpaid'),
        color: 'has-red-background',
        borderTop: 'has-red-border-top-10',
        borderLeft: 'has-red-border-left-10',
      }
    case 'PEN':
      return {
        text: this.$t('pending'),
        color: 'has-orange-background',
        borderTop: 'has-orange-border-top-10',
        borderLeft: 'has-orange-border-left-10',
      }
    case 'DWN':
      return {
        text: this.$t('downloaded'),
        color: 'has-primary-background',
        borderTop: 'has-primary-border-top-10',
        borderLeft: 'has-primary-border-left-10',
      }
    case 'INV':
      return {
        text: this.$t('invoiced'),
        color: 'has-orange-background',
        borderTop: 'has-orange-border-top-10',
        borderLeft: 'has-orange-border-left-10',
      }
    case 'CLO':
      return {
        text: this.$t('closed'),
        color: 'has-red-background',
        borderTop: 'has-red-border-top-10',
        borderLeft: 'has-red-border-left-10',
      }
    case 'OPE':
      return {
        text: this.$t('open'),
        color: 'has-primary-background',
        borderTop: 'has-primary-border-top-10',
        borderLeft: 'has-primary-border-left-10',
      }
    case 'READ':
      return {
        text: this.$t('read'),
        color: 'has-primary-background',
        borderTop: 'has-primary-border-top-10',
        borderLeft: 'has-primary-border-left-10',
      }
    case 'NOREAD':
      return {
        text: this.$t('noread'),
        color: 'has-red-background',
        borderTop: 'has-red-border-top-10',
        borderLeft: 'has-red-border-left-10',
        fontWeight: 'has-bold-text'
      }
    case 'IPR':
      return {
        text: this.$t('inProcessTicket'),
        color: 'has-orange-background',
        borderTop: 'has-orange-border-top-10',
        borderLeft: 'has-orange-border-left-10',
      }
    case 'CHA':
      return {
        text: this.$t('changeNumber'),
        color: 'has-grey-background',
        borderTop: 'has-grey-border-top-10',
        borderLeft: 'has-grey-border-left-10',
      }
    default:
      return {
        text: '',
        color: 'has-light-background',
        borderTop: '',
        borderLeft: ''
      }
  }
}

export const getMonthlyReadingsDataSet = (readings) => {
  const aux = readings
  const avgData = []
  const labels = []
  aux.reverse()
  const consumptions = aux.map((reading) => {
    const from = new Date(reading.from_date)
    const to = new Date(reading.to_date)
    const days = TimeManager.daysBetweenDates(from, to)
    const dailyAverage = (reading.consumption / days * 30).toFixed(2)
    avgData.push(dailyAverage)
    labels.push(formatDate(reading.to_date))
    return reading.consumption
  })

  return {
    labels,
    datasets: [{
      label: 'Consumo mensual',
      data: consumptions,
    },
    {
      label: 'Consumo medio 30 días',
      data: avgData,
    }]
  }
}

export const formatMonthlyData = (readings) => {
  if (readings.length === 0) return { avg: [], consumptions: [] }
  const avgData = []

  const consumptions = readings.map((reading) => {
    const from = new Date(reading.from_date)
    const to = new Date(reading.to_date)
    const days = TimeManager.daysBetweenDates(from, to)
    const dailyAverage = [
      formatDate(reading.to_date),
      (reading.consumption / days * 30).toFixed(2)
    ]
    avgData.push(dailyAverage)
    return [formatDate(reading.to_date), reading.consumption]
  })

  return {
    avg: avgData.reverse(),
    consumptions: consumptions.reverse()
  }
}

export const mapKeys = (list, keys) => list.map((item) => {
  const newItem = {}
  Object.keys(keys).forEach((key) => {
    newItem[keys[key]] = item[key]
  })
  return newItem
})

const JSONtoCSV = (arr, columns, delimiter = ',') => [
  columns.join(delimiter),
  ...arr.map(obj => columns.reduce(
    (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : obj[key]}"`,
    ''
  ))
].join('\n')

export const convertArrayOfObjectsToCSV = (items, delimiter = ',', headers = undefined) => {
  let data;
  if (headers) {
    data = mapKeys(items, headers)
  } else {
    data = items
  }
  const columns = (data.length > 0) ? Object.keys(data[0]) : []
  const csvElement = JSONtoCSV(data, columns, delimiter).replace(/\./g, ',');
  return csvElement
}

export const downloadFile = (href, filename, callback = null) => {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.setAttribute('href', href)
  link.setAttribute('target', '_blank')
  link.setAttribute('download', filename)
  link.click()
  document.body.removeChild(link)
  if (callback) {
    callback()
  }
}

export const downloadCSV = (data, filename, headers = undefined) => {
  const csv = convertArrayOfObjectsToCSV(data, ';', headers)
  downloadBlob(csv, filename)
}

/**
 * Saves the blob as a file with an specified filename
 * @param {Blob} data         Blob data
 * @param {String} filename   Filename
 */
export const download = (data, filename) => {
  save.saveAs(data, filename)
}

export const formatReadingDate = (date, hour = undefined, mode = 'daily') => {
  let formatted = ''

  const mDate = TimeManager.fromQueryString(date)
  mDate.setHours(hour)

  switch (mode) {
    case 'weekly':
      formatted = toDateTime(mDate)
      break
    case 'daily':
      formatted = hour < 10 ? `0${hour}:00` : `${hour}:00`
      break
    default:
      break
  }
  return formatted
}

export const KEYCODES = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40
}

export const isNumber = (value) => {
  try {
    const result = !Number.isNaN(+value)
    return result
  } catch (error) {
    return false
  }
}

/* eslint-disable */
export const JSONtoExcel = (jsonObject, headers) => {
  let xml;
  let data = typeof jsonObject !== 'object' ? JSON.parse(jsonObject) : jsonObject;

  // header generation
  let headerRow =  '<ss:Row>\n';
  for (let colName in headers) {
    headerRow += '  <ss:Cell>\n';
    headerRow += '    <ss:Data ss:Type="String">';
    headerRow += headers[colName] + '</ss:Data>\n';
    headerRow += '  </ss:Cell>\n';
  }
  headerRow += '</ss:Row>\n';
  xml = '<?xml version="1.0"?>\n' +
    '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n' +
    '<ss:Worksheet ss:Name="Sheet1">\n' +
    '<ss:Table>\n\n' + headerRow;

  // body generation
  for (let row = 0; row < data.length; row++) {
    xml += '<ss:Row>\n';
    for (let col in data[row]) {
      xml += '  <ss:Cell>\n';
      xml += '    <ss:Data ss:Type="String">';
      let value = data[row][col] !== undefined && data[row][col] !== null ? data[row][col] : ''
      xml += value.toString().replace(/\./g, ',') + '</ss:Data>\n';
      xml += '  </ss:Cell>\n';
    }
    xml += '</ss:Row>\n';
  }

  // footer generation
  xml += '\n</ss:Table>\n' +
  '</ss:Worksheet>\n' +
  '</ss:Workbook>\n';

  return xml;
}
/* eslint-enable */

export const convertArrayOfObjects = (items, headers = undefined) => {
  let data;
  if (headers) {
    data = mapKeys(items, headers)
  } else {
    data = items
  }
  const columns = Object.keys(data[0])
  return [columns, data]
}

export const downloadExcel = (data, filename, headers = undefined) => {
  const transformData = convertArrayOfObjects(data, headers)
  const headersToShow = transformData[0]
  const dataToShow = transformData[1]
  const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const content = JSONtoExcel(dataToShow, headersToShow)
  const blob = new Blob([content], { type: contentType })
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute('target', '_blank')
  link.setAttribute('download', filename)
  link.click()
  document.body.removeChild(link)
}
