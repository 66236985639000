import { NavigationBar } from '@/shared/components'
import {
  TOGGLE_FEEDBACK
} from '@/auth/authConstants'

export default {
  components: {
    NavigationBar
  },
  data() {
    return {
      message: '',
      showThanks: false
    }
  },
  methods: {
    toggleFeedback() {
      if (this.$store.state.auth.feedback) this.$store.dispatch(TOGGLE_FEEDBACK)
    },
    async sendFeedback() {
      if (this.message.length >= 10) {
        this.showThanks = true
        this.$http.sendFeedback(this.message)
        setTimeout(this.toggleFeedback, 500)
      }
    }
  }
}
