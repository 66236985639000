export default {
  data() {
    return {
      page: 1
    }
  },
  methods: {
    updatePage(page, callback) {
      this.page = page
      callback()
    },
  }
}
