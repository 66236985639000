export default {
  props: {
    showModal: { type: Boolean, default: false },
    onClose: { type: Function, default: undefined },
    title: { type: String, default: '' },
    buttons: { type: Array, default: () => [] }
  },
  methods: {
    close() {
      this.onClose()
    }
  }
}
