import { swiper, swiperSlide } from 'vue-awesome-swiper'
import UIStatus from '@/shared/mixins/UIStatus';
/* eslint-disable-next-line */
import 'swiper/dist/css/swiper.css'

export default {
  components: {
    swiper,
    swiperSlide
  },
  mixins: [UIStatus],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOption: {
        breakpointsInverse: true,
        breakpoints: {
          1088: {
            slidesPerView: 3,
            spaceBetween: 20,
            slidesPerGroup: 3,
          },
          769: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        speed: 200
      }
    }
  },
}
