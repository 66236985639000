import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import AuthStore from '@/auth/authStore'
import ClientsStore from '@/clients/clientsStore'
import ProfessionalsStore from '@/professionals/professionalsStore'

const vuexPersist = new VuexPersist({
  key: 'watium',
  storage: localStorage,

  // We only need to store the user's authentication info
  reducer: state => ({
    auth: state.auth
  })
})
Vue.use(Vuex)

export default new Vuex.Store({
  state: { ...AuthStore.state, ...ClientsStore.state, ...ProfessionalsStore.state },
  mutations: { ...AuthStore.mutations, ...ClientsStore.mutations, ...ProfessionalsStore.mutations },
  actions: { ...AuthStore.actions, ...ClientsStore.actions, ...ProfessionalsStore.actions },
  plugins: [
    vuexPersist.plugin,
  ]
})
