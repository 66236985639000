// STORE CONSTANTS FOR AUTHENTICATION VIEWS

export const STORE_LOGIN = 'LOGIN'
export const STORE_LOGOUT = 'LOGOUT'
export const STORE_SET_PERMISSIONS = 'STORE_SET_PERMISSIONS'
export const STORE_SET_NOTIFY = 'STORE_SET_NOTIFY'
export const STORE_SET_NAME = 'STORE_SET_NAME'
export const STORE_TOGGLE_UPDATE = 'STORE_TOGGLE_UPDATE'
export const STORE_SET_VERSION = 'STORE_SET_VERSION'
export const TOGGLE_FEEDBACK = 'TOGGLE_FEEDBACK'

// ROUTER CONSTANTS FOR AUTHENTICATION VIEWS

export const PATH_LOGIN = '/login'
export const PATH_LOGOUT = '/logout'
export const PATH_RECOVER_PASSWORD = '/reset-password'
export const PATH_RECOVER_PASSWORD_CONFIRM = '/reset-password/confirm'
