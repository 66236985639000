import { mapState } from 'vuex'
import { removeEmptyProperties } from '@/utils'
import {
  BadgeDropdown,
  Badge,
  Select as MSelect,
  SectionHeader,
  ListView,
  SearchBox,
  VueModal
} from '@/shared/components'

import {
  STORE_SET_MESSAGES,
  STORE_CLEAR_MESSAGES
} from '@/clients/clientsConstants'
import PersistentFilters from '@/shared/mixins/PersistentFilters'
import { Pagination, UIStatus } from '@/shared/mixins'
import LinksReplacer from '@/shared/mixins/LinksReplacer'

export default {
  components: {
    BadgeDropdown,
    SearchBox,
    MSelect,
    SectionHeader,
    ListView,
    Badge,
    VueModal
  },
  mixins: [Pagination, UIStatus, PersistentFilters, LinksReplacer],
  data() {
    return {
      checkedMessages: [],
      filtersStorageKey: 'messages.list',
      filtersConfig: {
        read: '',
        subject: ''
      },
      searchOptionsConfig: [
        {
          id: 'subject',
          text: this.$t('subject')
        }
      ],
      showModal: false,
      message: '',
      messageTitle: '',
      statuses: [
        { id: '', text: this.$t('anyStatus') },
        { id: 'read', text: this.$t('readMessages'), value: 'True' },
        { id: 'unread', text: this.$t('unreadMessages'), value: 'False' }
      ]
    }
  },
  computed: mapState({
    messages: state => state.clients.messages.results,
    total: state => state.clients.messages.count,
    currentPage: state => state.clients.messages.page,
    isEmpty: state => state.clients.messages.results.length === 0,
    hasNextPage: state => state.clients.messages.next !== null,
    globalFilters: state => state.clients.globalFilters,
    disableScroll() { return !(this.hasNextPage) },
  }),
  created() {
    this.setBlank()
    this.loadFilters()
    this.$store.dispatch(STORE_CLEAR_MESSAGES)
    this.fetchMessages()
  },
  methods: {
    getDropdownMessage() {
      let message = this.$t('anyStatus')
      if (this.filters.read === 'True') {
        message = this.$t('readMessages')
      }
      if (this.filters.read === 'False') {
        message = this.$t('unreadMessages')
      }
      return message
    },
    async fetchMessages() {
      try {
        this.setLoading()
        const params = removeEmptyProperties({
          ...this.filters, page: this.page, ...this.globalFilters
        })
        const results = await this.$http.list('messages', params)
        this.$store.dispatch(STORE_SET_MESSAGES, { ...results, page: this.page })
        this.setIdeal()
      } catch (error) {
        if (!error.message.isCancelled) this.setError(error.message)
      }
    },
    async markAsRead(message) {
      await this.$http.get('messages', message.id)
      await this.fetchMessages()
    },
    showMessage(message) {
      this.message = message.message
      this.messageTitle = message.subject
      this.showModal = true
      this.markAsRead(message)
    },
    nextPage() {
      if (!this.hasNextPage || this.loading) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchMessages()
      })
    },
    setCheckedMessages(messages) {
      this.checkedMessages = messages
    },
    removeFilters() {
      this.cleanFilters()
      this.$store.dispatch(STORE_CLEAR_MESSAGES)
      this.fetchMessages()
    },
    setFilter(filter, value) {
      this.filters[filter] = value
      this.saveFilters()
      this.$store.dispatch(STORE_CLEAR_MESSAGES)
      this.page = 1
      this.fetchMessages()
    },
    closeMessage() {
      this.showModal = !this.showModal
    }
  }
}
