import { required } from 'vuelidate/lib/validators'
import { Form } from '@/shared/mixins'
import { SearchAPI as SearchApi } from '@/shared/components'
import TicketGenerator from '../TicketGenerator'

export default {
  mixins: [Form],
  components: {
    SearchApi
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    defaultSubject: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true,
    }
  },
  validations: {
    subject: { required },
    comments: { required },
    contract: {},
    invoice: {}
  },
  data() {
    return {
      subject: this.defaultSubject ? this.defaultSubject : '',
      comments: '',
      invoice: '',
      contract: '',
    }
  },
  methods: {
    reset() {
      this.contract = null
      this.invoice = null
      this.comments = ''
      this.subject = ''
      this.$v.$reset()
    },
    postTicket() {
      let c
      if (this.contract) {
        c = this.contract.id
      } else if (this.invoice) {
        c = this.invoice.contract
      }
      const ticket = TicketGenerator.other({
        subject: this.subject,
        comments: this.comments,
        invoice: this.invoice ? this.invoice.id || this.invoice : null,
        contract: c,
      })
      this.$emit('send', ticket)
      this.reset()
    },
  },
}
