import {
  required, email, requiredIf, alphaNum
} from 'vuelidate/lib/validators'
import { isValid as validIBAN } from 'iban'
import {
  Checkbox,
  SearchAPI as SearchApi,
  BankAccountInput,
  DuplicatedTickets
} from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import { Form } from '@/shared/mixins'


const optionalEmail = value => value === '' || email(value)
const isChecked = value => value === true

export default {
  mixins: [Form],
  components: {
    Checkbox, SearchApi, BankAccountInput, DuplicatedTickets
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      required: true,
      type: String
    },
    selected: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    phoneNotInformed() {
      const phone = this.phone || ''
      const mobilePhone = this.mobilePhone || ''
      return phone === '' && mobilePhone === ''
    },
    paymentDataChangeChecked() {
      return this.requestPaymentDataChange || false
    },
    ibanValidator() {
      if (this.requestPaymentDataChange) {
        return !validIBAN(this.bankAccountParams) || false
      }
      return this.requestPaymentDataChange || false
    }
  },
  validations: {
    contract: { required },
    name: { required },
    nif: { required, alphaNum },
    address: { required },
    zip: { required },
    city: { required },
    state: { required },
    phone: { required: requiredIf('phoneNotInformed') },
    mobilePhone: { required: requiredIf('phoneNotInformed') },
    fax: { },
    email: { email: optionalEmail },
    paymentName: { required: requiredIf('paymentDataChangeChecked') },
    paymentNif: { required: requiredIf('paymentDataChangeChecked'), alphaNum },
    paymentAddress: { },
    paymentState: { },
    paymentCity: { },
    paymentZip: { },
    requestPaymentDataChange: { },
    acceptConditions: { required: isChecked },
    bankAccountParams: { required: requiredIf('ibanValidator') }
  },
  data() {
    return {
      contract: this.selected ? this.selected : {},
      name: this.params ? this.params.name : '',
      nif: this.params ? this.params.nif : '',
      address: this.params ? this.params.address : '',
      zip: this.params ? this.params.zip : '',
      city: this.params ? this.params.city : '',
      state: this.params ? this.params.state : '',
      phone: this.params ? this.params.phone : '',
      mobilePhone: this.params ? this.params.mobilePhone : '',
      fax: this.params ? this.params.fax : '',
      email: this.params ? this.params.email : '',
      paymentName: '',
      paymentNif: '',
      paymentAddress: '',
      paymentState: '',
      paymentCity: '',
      paymentZip: '',
      bankAccountParams: '',
      comments: '',
      updateSupplyAdminName: this.params ? this.params.updateSupplyAdminName : false,
      updatePostalAddress: this.params ? this.params.updatePostalAddress : false,
      requestPaymentDataChange: this.params ? this.params.requestPaymentDataChange : '',
      acceptConditions: false,
      keepData: false
    }
  },
  methods: {
    conditionsAccepted() {
      this.acceptConditions = true
      this.$emit('display-conditions')
    },
    postTicket() {
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.changeContractAdmin({
        contract: this.contract,
        rate: this.rate,
        name: this.name,
        nif: this.nif,
        address: this.address,
        zip: this.zip,
        city: this.city,
        state: this.state,
        phone: this.phone,
        mobilePhone: this.mobilePhone,
        fax: this.fax,
        email: this.email,
        paymentName: this.paymentName,
        paymentNif: this.paymentNif,
        paymentAddress: this.paymentAddress,
        paymentState: this.paymentState,
        paymentCity: this.paymentCity,
        paymentZip: this.paymentZip,
        iban: this.bankAccountParams.substring(0, 4),
        accountEntity: this.bankAccountParams.substring(4, 8),
        office: this.bankAccountParams.substring(8, 12),
        dc: this.bankAccountParams.substring(12, 14),
        account: this.bankAccountParams.substring(14, 24),
        comments: this.comments,
        updateSupplyAdminName: this.updateSupplyAdminName,
        updatePostalAddress: this.updatePostalAddress,
        requestPaymentDataChange: this.requestPaymentDataChange,
        acceptConditions: this.acceptConditions,
        keepData: this.keepData,
      }, this.type, user)

      if (!ticket.error && !this.keepData) {
        this.reset()
      }

      this.$emit('send', ticket)
    },
    reset() {
      this.contract = {}
      this.name = ''
      this.nif = ''
      this.address = ''
      this.zip = ''
      this.city = ''
      this.state = ''
      this.phone = ''
      this.mobilePhone = ''
      this.fax = ''
      this.email = ''
      this.paymentName = ''
      this.paymentNif = ''
      this.paymentAddress = ''
      this.paymentState = ''
      this.paymentCity = ''
      this.paymentZip = ''
      this.iban = ''
      this.office = ''
      this.dc = ''
      this.account = ''
      this.bankAccountParams = ''
      this.comments = ''
      this.updateSupplyAdminName = false
      this.updatePostalAddress = false
      this.requestPaymentDataChange = false
      this.acceptConditions = false
      this.keepData = false
      this.$v.$reset()
    },
    copyData() {
      this.name = this.contract.fullname
      this.nif = this.contract.customer_vat_id
      this.address = this.contract.address
      this.zip = this.contract.zip
      this.city = this.contract.city
      this.state = this.contract.state
      this.phone = this.contract.contact_phone ? this.contract.contact_phone : ''
      this.mobilePhone = this.contract.contact_mobile ? this.contract.contact_mobile : ''
      this.email = this.contract.email ? this.contract.email : ''
    }
  },
}
