import { Notification, Loading } from '@/shared/components'
import { UIStatus } from '@/shared/mixins'

export default {
  components: {
    Notification,
    Loading
  },
  mixins: [UIStatus],
  data() {
    return {
      password: '',
      password2: '',
      token: '',
      uid: '',
    }
  },
  created() {
    const { token, uid } = this.$route.query
    this.token = token
    this.uid = uid
  },
  methods: {
    async recoverPassword() {
      if (!this.token) {
        this.setError(`${this.$t('missingRequiredParams')}: ${this.$t('token')}`)
        return
      }
      if (!this.uid) {
        this.setError(`${this.$t('missingRequiredParams')}: ${this.$t('uid')}`)
        return
      }
      if (this.password === this.password2) {
        await this.postData()
      } else {
        this.setError(this.$t('passwordMismatch'))
      }
    },
    async postData() {
      try {
        await this.$http.resetPasswordConfirm(this.password, this.password2, this.uid, this.token)
        this.$router.push({ name: 'LoginView' }, () => {})
      } catch (error) {
        const response = error.response || {}
        const data = response.data || {}
        if (data.new_password2) {
          this.setError(this.$t('passwordInsecure'))
        } else {
          this.setError(data)
        }
      }
    }
  },
}
