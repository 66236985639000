import Arrow from '../Arrow'

export default {
  components: {
    Arrow,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      collapsed: true,
    }
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed
      this.$emit('collapse', this.collapsed)
    },
  },
}
