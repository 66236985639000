import { queryDate, formatDate } from '@/utils'
import SearchField from '../SearchField'

export default {
  components: {
    SearchField
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    fromDate: {
      type: String,
      default: ''
    },
    toDate: {
      type: String,
      default: ''
    },
    hasSearch: {
      type: Boolean,
      default: true
    },
    hasTitle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    hasFilterDate: {
      type: Boolean,
      default: false
    },
    dateFilterText: {
      type: String,
      default: 'Por fecha'
    },
    options: {
      type: Array,
      default: () => [],
      validator: (opts) => {
        // Every option must include the properties [id] and [text]
        let ok = true
        opts.forEach((option) => {
          ok = ok && Object.keys(option).indexOf('id') > -1 && Object.keys(option).indexOf('text') > -1
        })
        return ok
      },
    },
    multi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: undefined,
      filterOption: '',
      internalFromDate: this.fromDate,
      internalToDate: this.toDate
    }
  },
  computed: {
    defaultDateFilterText() {
      return this.$t('filterByDate')
    }
  },
  watch: {
    fromDate: {
      handler() {
        this.internalFromDate = this.fromDate
      }
    },
    toDate: {
      handler() {
        this.internalToDate = this.toDate
      }
    }
  },
  methods: {
    emit() {
      this.$emit('query', this.query)
    },
    selectFromDate() {
      this.$emit('fromDate', this.internalFromDate ? queryDate(this.internalFromDate) : '')
    },
    selectToDate() {
      this.$emit('toDate', this.internalToDate ? queryDate(this.internalToDate) : '')
    },
    clear() {
      this.query = ''
      this.$emit('query', this.query)
    },
    formatDate
  },
}
