import UIStatus from '@/shared/mixins/UIStatus'

import {
  Card,
  MiniCard,
  MiniCardList,
  MiniCardTable,
  Loading,
  Notification,
  SectionHeader,
  SectionHeaderTag
} from '@/shared/components'

import { formatInvoiceLines, download } from '@/utils'

export default {
  components: {
    MiniCardList,
    MiniCardTable,
    MiniCard,
    Card,
    SectionHeader,
    Notification,
    Loading,
    SectionHeaderTag
  },
  mixins: [UIStatus],
  data() {
    return {
      invoice: {},
      downloadActive: false,
    }
  },
  computed: {
    borderClass() {
      const status = this.invoice ? this.invoice.status || '' : ''
      return this.isTablet() ? this.getStatus(status).borderTop : this.getStatus(status).borderLeft
    },
    title() {
      return this.invoice
        ? `${this.$t('invoice')} #${this.invoice.idn}`
        : this.$t('unavailable')
    },
    lines() {
      return this.invoice ? formatInvoiceLines(this.invoice.invoice_lines) : []
    },
    headers() {
      const headers = []
      this.invoice.invoice_lines.map(i => headers.push(i.text))
      return headers
    },
    totalWithoutTax() {
      if (!this.invoice) return 0
      let total = 0
      if (this.invoice.invoice_lines instanceof Array) {
        // eslint-disable-next-line array-callback-return
        this.invoice.invoice_lines.map((line) => {
          total += line.total
        })
      }
      return total
    }
  },
  created() {
    this.fetchInvoice()
  },
  methods: {
    async fetchInvoice() {
      const { id } = this.$route.params

      try {
        this.setLoading()
        const invoice = await this.$http.get('invoices', id)
        this.invoice = invoice
        if (new Date(this.invoice.creation_date) >= Date.now()) {
          this.$router.push({ name: 'Client404NotFound' }, () => {})
        }
        this.setIdeal()
      } catch (error) {
        const response = error.response || {}
        if (response.status === 404) {
          this.$router.push({ name: 'Client404NotFound' }, () => {})
        } else {
          this.setError(error)
          // eslint-disable-next-line no-console
          console.log('error', error.message)
        }
      }
    },
    closeSnackbar() {
      this.downloadActive = false
    },
    pushToContractView() {
      this.$router.push({ name: 'ClientContractView', params: { id: this.invoice.contract_id } }, () => {})
    },
    async downloadInvoice() {
      const { id } = this.$route.params
      this.downloadActive = true
      try {
        const data = await this.$http.downloadInvoice(id, 'pdf')
        try {
          download(data, `${this.invoice.idn}.pdf`)
        } catch (e) {
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error', error)
        this.setError('error', error.message)
      }
      this.downloadActive = false
    }
  }
}
