/* eslint-disable no-unused-vars */
import { formatDate, removeEmptyProperties as _ } from '../../utils'
import { TICKET_STATUSES } from '@/config'

export default class TicketGenerator {
  static changeAddress({
    contract, name, address, zip, city,
    state, comments, keepData,
  }, type) {
    if (!contract || !name || !address || !zip || !city || !state) return { error: true }
    return _({
      contract_id: contract.id,
      subject: `Cambio de la dirección de correspondencia ${contract.id}`,
      type,
      status: TICKET_STATUSES.opened,
      meta: `Nombre: ${name}
Dirección: ${address}
Código postal: ${zip}
Ciudad: ${city}
Provincia: ${state}
CUPS: ${contract.cups}
Observaciones y comentarios:

${comments}
`
    })
  }

  static changeContractAdmin({
    contract, name, nif, address, zip, city, state,
    phone, mobilePhone, fax, email,
    paymentName, paymentNif, paymentAddress,
    paymentZip, paymentCity, paymentState,
    iban, accountEntity, office, dc,
    account, comments, updateSupplyAdminName,
    updatePostalAddress, requestPaymentDataChange,
    acceptConditions, keepData,
  }, type, user) {
    if (!acceptConditions) {
      return {
        error: true,
        message: 'Tienes que aceptar las condiciones'
      }
    }

    if (!contract || !name || !nif || !address || !zip
        || !city || !state) {
      return { error: true, message: '' }
    }

    const newSupplyAdminName = updateSupplyAdminName ? 'Se solicita que se usen estos datos para actualizar el nombre del titular del suministro.\n\n' : ''

    const newPaymentData = requestPaymentDataChange ? `Se solicita cambio de la información de pago con los siguientes datos. \n
Nombre: ${paymentName}
NIF: ${paymentNif}
Dirección pagador: ${paymentAddress}
Ciudad pagador: ${paymentCity}
Código postal pagador: ${paymentZip}
Provincia pagador: ${paymentState}
Número de cuenta: ${iban}-${accountEntity}-${office}-${dc}-${account}\n` : ''

    const newPostalAddress = updatePostalAddress ? 'Se solicita que estos datos se utilicen para actualizar la dirección de correspondencia.\n\n' : ''

    return {
      contract_id: contract.id,
      subject: `Cambio de titular del contrato ${contract.id}:\n`,
      meta: `
Titular: ${name}
NIF/CIF: ${nif}
CUPS: ${contract.cups}
Dirección: ${address} ${zip} ${city} ${state}
Teléfono: ${phone}
Teléfono móvil: ${mobilePhone}
Fax: ${fax}
E-Mail: ${email}

${newSupplyAdminName}
${newPaymentData}
${newPostalAddress}

Observaciones y comentarios:

${comments}`,
      // ${acceptConditions ? 'El usuario ha aceptado las condiciones.\n\n': ''}
      // ${keepData ? 'El usuario ha solicitado que se mantengan los datos.\n\n': ''}
      type,
      status: TICKET_STATUSES.opened,
    }
  }

  static changeAdmin({
    contract, newAgreement, currentAgreement, email, phone, mobilePhone, fax, name, address, zip, city, state, paymentName,
    paymentNif, paymentAddress, paymentZip, paymentCity, paymentState, iban, accountEntity, office, dc, account, comments,
    updatePostalAddress, requestPaymentDataChange
  }, type) {
    if (!contract) {
      return { error: true }
    }

    if (updatePostalAddress) {
      if (name === '' || address === '' || zip === '' || city === '' || state === '') {
        return {
          error: true,
          message: 'Todos los campos son obligatorios si quiere solicitar cambio de correspondencia en esta misma gestión'
        }
      }
    }

    if (requestPaymentDataChange) {
      if (paymentName === '' || paymentNif === '' || iban === '' || accountEntity === ''
          || office === '' || dc === '' || account === '') {
        return {
          error: true,
          message: 'Todos los campos son obligatorios si quiere solicitar cambio de datos de pago en esta misma gestión'
        }
      }
    }

    const newPostalAddress = updatePostalAddress ? `Se solicita cambio de correspondencia con los siguientes datos.

Nombre: ${name}
Dirección: ${address} ${zip} ${city} ${state}` : ''

    const newPaymentData = requestPaymentDataChange ? `
Se solicita cambio de la información de pago con los siguientes datos.

Nombre: ${paymentName}
NIF: ${paymentNif}
Dirección pagador: ${paymentAddress}
Ciudad pagador: ${paymentCity}
Código postal pagador: ${paymentZip}
Provincia pagador: ${paymentState}
Número de cuenta: ${iban}-${accountEntity}-${office}-${dc}-${account}` : ''

    return _({
      contract_id: contract.id,
      subject: `Cambio de administrador del contrato ${contract.id}`,
      meta: `
Convenio Actual: ${currentAgreement}
Nuevo Convenio: ${newAgreement}
CUPS: ${contract.cups}
E-Mail: ${email}
Teléfono: ${phone}
Teléfono móvil: ${mobilePhone}
Fax: ${fax}

${newPaymentData}
${newPostalAddress}

Observaciones y comentarios:
        
${comments}`,
      // ${acceptConditions ? 'El usuario ha aceptado las condiciones.\n\n': ''}
      // ${keepData ? 'El usuario ha solicitado que se mantengan los datos.\n\n': ''}
      type,
      status: TICKET_STATUSES.opened
    })
  }

  static changePaymentData({
    contract, name, address, city, state, zip, nif,
    iban, accountEntity, office, dc,
    account, comments, keepData,
  }, type, user) {
    if (!contract || !name || !nif) { return { error: true } }
    return {
      contract_id: contract.id,
      subject: 'Cambio de la información de pago',
      meta: `Titular: ${name}
NIF: ${nif}
Dirección: ${address}
Ciudad: ${city}
Código postal: ${zip}
Provincia: ${state}
CCC: ${iban} ${accountEntity} ${office} ${dc} ${account}
CUPS: ${contract.cups}

Observaciones y comentarios:
        
${comments}`,
      // ${keepData ? 'El usuario ha solicitado que se mantengan los datos.\n\n': ''}
      type,
      status: TICKET_STATUSES.opened,
    }
  }


  static supplyReadingIssue({
    contract, date,
    p1, p2, p3,
    p4, p5, p6,
    comments,
  }, type, user) {
    if (!contract || !date) return { error: true }
    return {
      contract_id: contract.id,
      subject: `Lectura de contador con fecha ${formatDate(date)}`,
      meta: `Fecha de lectura: ${formatDate(date)}
CUPS: ${contract.cups}
${p1 ? `P1: ${p1} kWh\n` : ''}
${p2 ? `P2: ${p2} kWh\n` : ''}
${p3 ? `P3: ${p3} kWh\n` : ''}
${p4 ? `P4: ${p4} kWh\n` : ''}
${p5 ? `P5: ${p5} kWh\n` : ''}
${p6 ? `P6: ${p6} kWh\n` : ''}

Observaciones y comentarios:

${comments}`,
      type,
      status: TICKET_STATUSES.opened,
    }
  }

  static changePower({
    contract, rate, comments, contactPhone, contactEmail,
    p1, p2, p3,
    p4, p5, p6
  }, type, user) {
    if (!contract || !rate) return { error: true }
    return _({
      contract_id: contract.id,
      subject: `Cambio de potencia en contrato #${contract.id}`,
      meta: `El usuario ${user.username} solicita un cambio de potencia.
Datos del contrato ${contract.id}- ${contract.cups} - ${contract.fullname ? contract.fullname : contract.name} - ${contract.customer_vat_id}.
CUPS: ${contract.cups}
Nueva Tarifa: ${rate}
Los datos de las nuevas potencias son:
  ${p1 ? `P1: ${p1} kW` : ''}
  ${p2 ? `P2: ${p2} kW` : ''}
  ${p3 ? `P3: ${p3} kW` : ''}
  ${p4 ? `P4: ${p4} kW` : ''}
  ${p5 ? `P5: ${p5} kW` : ''}
  ${p6 ? `P6: ${p6} kW` : ''}
Datos de Contacto:
Teléfono: ${contactPhone}
Email: ${contactEmail}

Observaciones y comentarios:        
${comments}`,
      type,
      status: TICKET_STATUSES.opened,
    })
  }

  static default({ subject, comments, type }) {
    if (!subject) return { error: true }
    return _({
      subject,
      meta: comments,
      type,
      status: TICKET_STATUSES.opened,
    })
  }

  static other({
    subject, comments, invoice, contract, type
  }) {
    if (!subject) return { error: true }
    return _({
      contract_id: contract,
      invoice_id: invoice,
      subject,
      type,
      status: TICKET_STATUSES.opened,
      meta: comments
    })
  }

  static invoiceIssue({
    invoice, contract, comments, idn
  }, type, user) {
    if (!invoice) return { error: true }
    return _({
      invoice_id: invoice,
      contract_id: contract,
      subject: `Incidencia con la factura ${idn}`,
      type,
      meta: comments
    })
  }

  static requestInvoicesIntegration({
    email, comments, aboutEmail, integration, agreement, maxInvoices, frequency, apiKey, nif,
  }, type, user) {
    if (!integration) return { error: true }
    return _({
      email,
      subject: `Solicitud de integracion de facturas ${email}`,
      type,
      comments,
      aboutEmail,
      integration,
      agreement,
      maxInvoices,
      frequency,
      apiKey,
      nif,
      meta: comments
    })
  }
}
