import { mapState } from 'vuex'
import {
  Arrow,
  Badge,
  BadgeDropdown,
  ListView,
  Select as MSelect,
  SelectAPI as MSelectApi,
  SearchBox,
  SectionHeader,
  ToastElementsCount,
  Toast
} from '@/shared/components'

import UIStatus from '@/shared/mixins/UIStatus'
import Pagination from '@/shared/mixins/Pagination'
import PersistentFilters from '@/shared/mixins/PersistentFilters'
import { STORE_SET_CONTRACTS, STORE_CLEAR_CONTRACTS, STORE_SET_AGREEMENTS } from '@/clients/clientsConstants'
import { downloadExcel, downloadCSV } from '@/utils'

export default {
  components: {
    Arrow,
    Badge,
    ListView,
    MSelect,
    MSelectApi,
    SectionHeader,
    SearchBox,
    BadgeDropdown,
    ToastElementsCount,
    Toast
  },
  mixins: [UIStatus, Pagination, PersistentFilters],
  data() {
    return {
      checkedContracts: [],
      filtersStorageKey: 'contracts.list',
      filtersConfig: {
        status: '',
        ordering: '-idn',
        agreement: null
      },
      statuses: [
        { id: '', text: this.$t('anyone') },
        { id: 'ACT', text: this.$t('active') },
        { id: 'INA', text: this.$t('inactive') },
        { id: 'APP', text: this.$t('pending') },
        { id: 'CHA', text: this.$t('changeNumber') },
      ],
      searchOptionsConfig: [
        {
          id: 'id',
          text: this.$t('contract')
        },
        {
          id: 'vat_id',
          text: this.$t('nif')
        },
        {
          id: 'cups',
          text: this.$t('cups')
        },
        {
          id: 'search',
          text: this.$t('all')
        }
      ],
      snackBarMessage: null,
      showCheckedContractBool: true,
      contractHasNextPage: false,
      hasCheckAll: false,
      hasFinishLoadDownload: false,
      downloadType: '',
      isDownloadBool: false
    }
  },
  computed: mapState({
    agreements(state) {
      return state.clients.agreements.results.map(a => ({
        id: a.code,
        text: a.code ? a.code : this.$t('anyone')
      }))
    },
    contracts: state => state.clients.contracts.results || [],
    total: state => state.clients.contracts.count || 0,
    currentPage: state => state.clients.contracts.page || 1,
    isEmpty: (state) => {
      const clients = state.clients || {}
      const contracts = clients.contracts || {}
      const results = contracts.results || {}
      const length = results.length || 0
      return length === 0
    },
    hasNextPage: state => state.clients.contracts.next !== null,
    globalFilters: state => state.clients.globalFilters || {},
    downloadActive() { return this.snackBarMessage !== null },
    contractsLoadCount: (state) => {
      const clients = state.clients || {}
      const contracts = clients.contracts || {}
      const results = contracts.results || {}
      return results.length || 0
    },
    showCheckedContract() { return this.checkedContracts.length !== 0 },
    numCheckedContract() { return this.checkedContracts.length },
    disableScroll() { return !(this.hasNextPage) },
    showIsDownload() { return this.isDownloadBool }
  }),
  created() {
    this.setBlank()
    this.loadFilters()
    this.$store.dispatch(STORE_CLEAR_CONTRACTS)
    this.fetchContracts()
  },
  watch: {
    showCheckedContract() {
      this.showCheckedContractBool = true
    },
    contractHasNextPage() {
      if (this.contractHasNextPage) {
        this.nextPage(this.hasCheckAll)
      }
    },
    hasFinishLoadDownload() {
      if (this.hasFinishLoadDownload) {
        this.isDownloadBool = false
        this.downloadFile(this.downloadType)
      }
    },
    showIsDownload() {
      if (!this.hasFinishLoadDownload) {
        this.isDownloadBool = true
      } else {
        this.isDownloadBool = false
      }
    },
  },
  methods: {
    getDropdownMessage() {
      if (this.filters.status === '' && this.filters.sub_status === 'CHA') {
        return this.$t('changeNumber')
      }
      if (this.filters.status === '' && this.filters.sub_status === 'APP') {
        return this.$t('pending')
      }
      switch (this.filters.status) {
        case 'ACT':
          return this.$t('active')
        case 'INA':
          return this.$t('inactive')
        default:
          return this.$t('anyStatus')
      }
    },
    async fetchContracts(download = false) {
      try {
        if (download) {
          this.contractHasNextPage = false
        }
        this.setLoading()
        const params = this.removeEmptyProperties({
          ...this.filters, page: this.page, ...this.globalFilters
        })
        const results = await this.$http.list('contracts', params)
        this.$store.dispatch(STORE_SET_CONTRACTS, { ...results, page: this.page })
        if (download) {
          if (results.results.length !== 0) {
            results.results.forEach(element => this.checkedContracts.push(element))
          }
          if (results.next !== null) {
            this.contractHasNextPage = true
            this.hasFinishLoadDownload = false
          } else {
            this.contractHasNextPage = false
            this.hasFinishLoadDownload = true
          }
        }
        this.setIdeal()
      } catch (error) {
        if (!error.message.isCancelled) this.setError(error.message)
      }
    },
    closeSnackBar() {
      this.snackBarMessage = null
    },
    downloadFile(type) {
      this.snackBarMessage = `${this.$t('downloadingFile')}`
      if (type === 'csv') {
        try {
          downloadCSV(this.checkedContracts, `${this.$t('contracts')}.csv`, {
            id: 'Número de contrato',
            cups: 'CUPS',
            supply_address: 'Dirección de suministro',
            status: 'Estado',
            rate: 'Tarifa'
          })
        } catch (e) {
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        }
      } else if (type === 'xls') {
        try {
          downloadExcel(this.checkedContracts, `${this.$t('contracts')}.xls`, {
            id: 'Número de contrato',
            cups: 'CUPS',
            supply_address: 'Dirección de suministro',
            status: 'Estado',
            rate: 'Tarifa'
          })
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        } catch (e) {
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        }
      }
      this.showCheckedContractBool = null
    },
    download(type) {
      this.downloadType = type
      this.isDownloadBool = false
      if (this.hasCheckAll) {
        if (!this.hasNextPage) {
          this.downloadFile(type)
        } else {
          this.nextPage(true)
        }
      } else if (this.checkedContracts.length > 0) {
        this.downloadFile(type)
      }
      this.isDownloadBool = null
    },
    nextPage(download = false) {
      if (!this.hasNextPage || this.loading) return
      this.updatePage(this.page + 1, async () => {
        await this.fetchContracts(download)
      })
    },
    setCheckedContracts(contracts) {
      this.checkedContracts = contracts
    },
    checkAll(checkAll) {
      this.hasCheckAll = checkAll
    },
    setQueryFilter(filter, value) {
      this.filters = {
        activation_date_from: this.filters.activation_date_from,
        activation_date_to: this.filters.activation_date_to,
        status: this.filters.status,
        agreement: this.filters.agreement,
        [filter]: value
      }
      this.$store.dispatch(STORE_CLEAR_CONTRACTS)
      this.page = 1
      this.fetchContracts()
    },
    setFilter(filter, value) {
      if (filter === 'status') {
        if (value === 'CHA' || value === 'APP') {
          this.filters.sub_status = value
          this.filters.status = ''
        } else {
          this.filters.status = value
          this.filters.sub_status = ''
        }
      } else {
        this.filters[filter] = value
      }
      this.saveFilters()
      this.$store.dispatch(STORE_CLEAR_CONTRACTS)
      this.page = 1
      this.fetchContracts()
    },
    removeFilters() {
      this.cleanFilters()
      this.$store.dispatch(STORE_CLEAR_CONTRACTS)
      this.fetchContracts()
    },
    getContractStatus(contract) {
      const { status, sub_status } = contract
      let result
      if (status === 'ACT') return this.getStatus('ACT')
      if (status === 'INA' && sub_status !== status) {
        result = sub_status === 'CHA' ? this.getStatus('CHA') : this.getStatus(status)
      } else if (status === 'INA') {
        result = this.getStatus('INA')
      } else if (status === 'DRF') {
        result = this.getStatus('PEN')
      } else {
        result = this.getStatus(status)
      }
      return result
    },
    agreementsModifier(response) {
      if (response.count > 0) this.$store.dispatch(STORE_SET_AGREEMENTS, response)
    }
  }
}
