import MiniCard from '../MiniCard'

export default {
  components: {
    MiniCard
  },
  props: {
    lines: {
      type: Array,
      required: true
    }
  },
}
