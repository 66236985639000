import Checkbox from '../Checkbox'

export default {
  components: {
    Checkbox
  },
  props: {
    checkable: {
      type: Boolean,
      required: false
    },
    items: {
      type: Array,
      required: true,
      default: []
    },
    loading: {
      type: Boolean
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    checkBoxLimit: {
      type: Number,
      default: 10000
    }
  },
  data() {
    return {
      checkedItems: []
    }
  },
  computed: {
    checkedAll() {
      return this.checkedItems.length > 0 && (this.checkedItems.length >= this.checkBoxLimit || this.checkedItems.length >= this.items.length)
    },
    showEmpty() {
      return this.items.length < 1
    }
  },
  methods: {
    check(item) {
      if (this.isChecked(item)) {
        this.checkedItems.splice(this.checkedItems.indexOf(item), 1)
      } else {
        this.checkedItems.push(item)
      }
      this.$emit('check', this.checkedItems)
    },
    checkAll() {
      if (this.checkedAll) {
        this.checkedItems = []
      } else {
        this.checkedItems = []
        this.checkedItems.push(...this.items.slice(0, this.checkBoxLimit))
      }
      this.$emit('allChecked', this.checkedAll)
      this.$emit('check', this.checkedItems)
    },
    isChecked(item) {
      return this.checkedItems.indexOf(item) > -1
    },
  },
}
