import {
  Badge,
  BadgeDropdown,
  Card,
  MiniCard,
  MiniCardList,
  Notification,
  Options,
  SectionHeader,
  SectionHeaderTag,
  Loading,
  Select as MSelect,
  SimpleSlider,
  Snackbar
} from '@/shared/components'

import { UIStatus } from '@/shared/mixins'
import {
  queryDate,
  downloadCSV,
  formatReadingDate,
  TimeManager,
  download,
  removeEmptyProperties as _,
} from '@/utils'
import { TICKET_TYPES } from '@/config'

export default {
  components: {
    Badge,
    BadgeDropdown,
    SectionHeader,
    MiniCardList,
    MiniCard,
    Notification,
    Options,
    Card,
    Loading,
    MSelect,
    SectionHeaderTag,
    SimpleSlider,
    Snackbar
  },
  mixins: [UIStatus],
  data() {
    return {
      contract: {},
      activeTab: 0,
      downloadActive: false,
      invoices: {
        results: []
      },
      readings: [],
      hourlyReadings: [],
      tickets: {
        results: []
      },
      loadingMonthlyData: true,
      loadingHourlyData: false,
      mode: 'daily', // daily, weekly
      startDate: queryDate(TimeManager.substract(new Date(), 7, TimeManager.DAY)),
      filters: {
        hourly: 1,
        from_date: queryDate(TimeManager.substract(new Date(), 7, TimeManager.DAY)),
        to_date: queryDate(TimeManager.substract(new Date(), 7, TimeManager.DAY))
      },
      loadingInvoices: true,
      loadingInvoicesConsumptions: true,
      loadingTickets: true,
      ticketOptions: [
        { id: TICKET_TYPES.T_CONSUMPTION, text: this.$t('setSupplyReading') },
        { id: TICKET_TYPES.T_CH_NAME, text: this.$t('changeContractHolder') },
        { id: TICKET_TYPES.T_CH_PAYMENT, text: this.$t('changePaymentData') },
        { id: TICKET_TYPES.T_CH_POSTAL, text: this.$t('changeAddress') },
        { id: TICKET_TYPES.T_CH_POWER, text: this.$t('changePower') },
      ],
      invoicesConsumptionsData: [],
      invoiceFilters: {},
      TICKET_TYPES
    }
  },
  computed: {
    contractInvoices() {
      return this.invoices ? this.invoices.results || [] : []
    },
    fiscalAddressDetails() {
      const city = this.contract.customer_city || ''
      const state = this.contract.customer_state || ''
      return `${this.contract.customer_zip}, ${city.toUpperCase()}, ${state.toUpperCase()}`
    },
    postalAddressDetails() {
      const city = this.contract.customer_city || ''
      const state = this.contract.customer_state || ''
      return `${this.contract.zip}, ${city.toUpperCase()}, ${state.toUpperCase()}`
    },
    supplyAddress() {
      return `${this.contract.supply_address}, ${this.contract.supply_city}, ${this.contract.supply_zip}, ${this.contract.supply_state}`;
    },
    showNewTicket() {
      return this.contract && this.contract.status !== 'INA'
    },
    borderClass() {
      return this.isTablet() ? this.getContractStatus(this.contract).borderTop : this.getContractStatus(this.contract).borderLeft
    },
    monthlyData() {
      const consumptions = []
      const avg = []

      if (this.readings && this.readings.forEach) {
        this.readings.forEach((reading) => {
          consumptions.push(reading.graph_point)
          avg.push(reading.avg_graph_point)
        })
      }

      return [{
        name: this.$t('monthlyConsumption'),
        data: consumptions.reverse()
      }, {
        name: this.$t('averageConsumption'),
        data: avg.reverse()
      }]
    },
    hourlyData() {
      if (this.hourlyReadings && this.hourlyReadings.map) {
        const parsedReadings = this.hourlyReadings.map(r => ([
          formatReadingDate(r.date, r.hour, this.mode),
          r.energy * 1000
        ]))
        return parsedReadings
      }
      return []
    },
    address() {
      return `${this.contract.supply_address}, ${this.contract.supply_city}, ${this.contract.supply_zip}, ${this.contract.supply_state}`.replace(',', ' ')
    },
    title() {
      if (!this.contract) {
        return ''
      }
      return `${this.$t('contract')} #${this.contract.id} ${this.contract.history_id && this.contract.sub_status === 'CHA' ? `> #${this.contract.history_id}` : ''}`
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.setLoading()
        await this.fetchContractDetails()
        this.setIdeal()
      } catch (error) {
        this.setError(error)
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    async fetchContractDetails() {
      const { id } = this.$route.params

      try {
        this.contract = await this.$http.get('contracts', id)
        this.fetchContractReadings()
        this.fetchHourlyReadings()
        this.fetchContractInvoices()
        this.fetchContractTickets()
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$router.push({ name: 'Client404NotFound' }, () => {})
        } else {
          this.setError(error)
          // eslint-disable-next-line no-console
          console.log('error', error)
        }
      }
    },
    async fetchContractReadings() {
      const id = this.contract.iid

      if (!id) {
        return
      }

      try {
        const rs = await this.$http.getContractReadings(id, this.filters)
        this.readings = rs
      } catch (error) {
        this.setError('Readings not found')
        // eslint-disable-next-line no-console
        console.log('error fetching readings', error)
      }

      this.loadingMonthlyData = false
    },
    async fetchContractInvoices() {
      const { id } = this.$route.params
      const { invoiced_date_from, invoiced_date_to } = this.invoiceFilters

      try {
        this.loadingInvoices = true
        this.invoices = await this.$http.list('invoices', _({
          contract_id: id,
          ordering: '-id',
          invoiced_date_from,
          invoiced_date_to
        }))
        this.loadingInvoices = false
      } catch (error) {
        this.setError(error)
      }
    },
    async fetchHourlyReadings() {
      this.loadingHourlyData = true
      try {
        const readings = await this.$http.getHourlyReadings(this.contract.cups, this.filters)
        this.hourlyReadings = readings
        if (this.hourlyReadings === null) {
          this.hourlyReadings = []
        }
        this.setIdeal()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error fetching hourly data', error)
        this.setError(error.message)
      }
      this.loadingHourlyData = false
    },
    async fetchContractTickets() {
      const { id } = this.$route.params

      try {
        const tickets = await this.$http.list('tickets', { contract_id: id, ordering: '-id' })
        this.tickets = tickets
      } catch (error) {
        this.setError(error)
      }
      this.loadingTickets = false
    },
    async fetchInvoicesConsumptions() {
      this.loadingInvoicesConsumptions = true
      const results = this.invoices ? this.invoices.results || [] : []
      Promise.all(
        results.slice(0, 6).map(async (invoice) => {
          const i = await this.$http.getInvoicesConsumptions(invoice.idn)
          this.invoicesConsumptionsData.push(i)
        }),
      )
        .then(() => { this.loadingInvoicesConsumptions = false })
        .then(() => this.invoicesConsumptionsData.sort((prev, next) => Number.parseInt(prev.idn, 10) < Number.parseInt(next.idn, 10)))
        .catch((error) => {
          this.setError(error)
          this.loadingInvoicesConsumptions = false
        })
    },
    pushToContractView() {
      this.$router.push({ name: 'ClientContractView', params: { id: this.contract.history_id } }, () => {})
    },
    changeModeFilter() {
      this.changeDateFilter()
    },
    changeDateFilter() {
      let finishDate
      if (this.mode === 'weekly') {
        const aux = TimeManager.fromQueryString(this.startDate)
        finishDate = queryDate(TimeManager.add(aux, 7, TimeManager.DAY))
      } else {
        finishDate = this.startDate
      }
      this.setFilter('from_date', this.startDate, undefined)
      this.setFilter('to_date', finishDate, this.fetchHourlyReadings)
    },
    download(readings, type = 'monthly') {
      let r = []
      let headers = {}
      switch (type) {
        case 'hourly':
          headers = {
            date: 'Hora',
            energy: 'Energía'
          };
          r = readings.map(reading => ({
            date: reading[0],
            energy: reading[1]
          }))
          break;
        default:
          headers = {
            consumption: 'Consumo',
            average: 'Media',
            from_date: 'Fecha Desde',
            to_date: 'Fecha Hasta'
          };
          r = readings.map(reading => ({
            consumption: reading.consumption,
            average: reading.average,
            from_date: reading.from_date.split('T')[0],
            to_date: reading.to_date.split('T')[0]
          }))
          break;
      }
      try {
        downloadCSV(r, `${this.$t('readings')}.csv`, headers)
      } catch (e) {
        this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
      }
    },
    setInvoices(invoices) {
      this.invoices = invoices
    },
    setTickets(tickets) {
      this.tickets = tickets
    },
    setFilter(filter, value, callback) {
      this.filters[filter] = value
      this.page = 1
      if (callback) { callback() }
    },
    setInvoiceFilter(filter, value, callback) {
      this.invoiceFilters[filter] = value
      this.page = 1
      if (callback) { callback() }
    },
    createTicket(type) {
      this.$router.push({ name: 'ClientTicketCreateView', params: { selected: this.contract, type } }, () => {})
    },
    getContractStatus(contract) {
      const { status, sub_status } = contract || {}
      if (status === 'ACT') {
        return this.getStatus('ACT')
      }
      let result
      if (status === 'INA' && sub_status !== status) {
        result = sub_status === 'CHA' ? this.getStatus('CHA') : this.getStatus(status)
      } else if (status === 'INA') {
        result = this.getStatus('INA')
      } else if (status === 'DRF') {
        result = this.getStatus('PEN')
      } else {
        result = this.getStatus(status)
      }
      return result
    },
    hasMaximeter(which, reading) {
      switch (which) {
        case 'p1':
          return reading.power_demand_p1 !== this.contract.power_p1 && reading.power_demand_p4 !== this.contract.power_p4
        case 'p2':
          return reading.power_demand_p2 !== this.contract.power_p2 && reading.power_demand_p5 !== this.contract.power_p5
        case 'p3':
          return reading.power_demand_p3 !== this.contract.power_p3 && reading.power_demand_p6 !== this.contract.power_p6
        default:
          return false
      }
    },
    hasSixPeriods() {
      return this.contract.rate.startsWith('T6');
    },
    getConsumptionForPeriod(period, counter) {
      const consumption = counter.consumptions || {}
      switch (period) {
        case 1:
          if (this.contract.rate.startsWith('T3')) {
            return consumption.p1 || 0 + consumption.p4 || 0;
          }
          return consumption.p1 || 0
        case 2:
          if (this.contract.rate.startsWith('T3')) {
            return consumption.p2 || 0 + consumption.p5 || 0;
          }
          return consumption.p2 || 0
        case 3:
          if (this.contract.rate.startsWith('T3')) {
            return consumption.p3 || 0 + consumption.p6 || 0;
          }
          return consumption.p3 || 0
        case 4:
          return consumption.p4 || 0
        case 5:
          return consumption.p5 || 0
        case 6:
          return consumption.p6 || 0
        default:
      }
      return 0;
    },
    getMaximeterForPeriod(period, counter) {
      const maximeter = counter.maximeter || {}
      switch (period) {
        case 1:
          if (this.contract.rate.startsWith('T3')) {
            return Math.max(maximeter.p1 || 0, maximeter.p4 || 0);
          }
          return maximeter.p1 || 0
        case 2:
          if (this.contract.rate.startsWith('T3')) {
            return Math.max(maximeter.p2 || 0, maximeter.p5 || 0);
          }
          return maximeter.p2 || 0
        case 3:
          if (this.contract.rate.startsWith('T3')) {
            return Math.max(maximeter.p3 || 0, maximeter.p6 || 0);
          }
          return maximeter.p3 || 0
        case 4:
          return maximeter.p4 || 0
        case 5:
          return maximeter.p5 || 0
        case 6:
          return maximeter.p6 || 0
        default:
      }
      return 0;
    },
    isDanger() {
      // TODO
      return false
    },
    isWarning() {
      // TODO
      return false
    },
    closeSnackbar() {
      this.downloadActive = false
    },
    async downloadContract() {
      const { id } = this.$route.params
      this.downloadActive = true

      try {
        const data = await this.$http.downloadContract(id, 'pdf')
        try {
          download(data, `${this.contract.id}.pdf`)
        } catch (e) {
          this.snackBarMessage = `${this.$t('errorWhileDownloadingFile')}`
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error', error)
        this.setError('error', error.message)
      }
      this.downloadActive = false
    },
    handleTabChange(index) {
      if (index === 2 && this.invoicesConsumptionsData.length === 0) {
        this.fetchInvoicesConsumptions()
      }
    }
  }
}
