import Vue from 'vue'
import VueI18n from 'vue-i18n'
import es from '@/locales/es.json'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const KEY = 'es'
  switch (KEY) {
    default:
      return { es }
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages: loadLocaleMessages()
})
