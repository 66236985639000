import {
  required,
  decimal,
  helpers,
  requiredUnless
} from 'vuelidate/lib/validators'
import { SearchAPI as SearchApi, DuplicatedTickets } from '@/shared/components'
import TicketGenerator from '../../TicketGenerator'
import Form from '@/shared/mixins/Form'

const dateValidator = helpers.regex('date', /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)

export default {
  mixins: [Form],
  components: { SearchApi, DuplicatedTickets },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    type: {
      required: true,
      type: String
    },
    selected: {
      type: Object,
      default: null
    }
  },
  computed: {
    somePeriodInformed() {
      return this.p1 !== '' || this.p2 !== '' || this.p3 !== '' || this.p4 !== '' || this.p5 !== '' || this.p6 !== ''
    }
  },
  validations: {
    contract: { required },
    date: { dateValidator },
    p1: { decimal, required: requiredUnless('somePeriodInformed') },
    p2: { decimal, required: requiredUnless('somePeriodInformed') },
    p3: { decimal, required: requiredUnless('somePeriodInformed') },
    p4: { decimal, required: requiredUnless('somePeriodInformed') },
    p5: { decimal, required: requiredUnless('somePeriodInformed') },
    p6: { decimal, required: requiredUnless('somePeriodInformed') },
    comments: {}
  },
  data() {
    return {
      contract: this.selected ? this.selected : {},
      date: null,
      p1: '',
      p2: '',
      p3: '',
      p4: '',
      p5: '',
      p6: '',
      comments: ''
    }
  },
  methods: {
    postTicket() {
      const { user } = this.$store.state.auth
      const ticket = TicketGenerator.supplyReadingIssue({
        contract: this.contract,
        date: this.date,
        p1: this.p1,
        p2: this.p2,
        p3: this.p3,
        p4: this.p4,
        p5: this.p5,
        p6: this.p6,
        comments: this.comments,
      }, this.type, user)

      if (!ticket.error) {
        this.reset()
      }

      this.$emit('send', ticket)
    },
    reset() {
      this.contract = {}
      this.date = null
      this.p1 = ''
      this.p2 = ''
      this.p3 = ''
      this.p4 = ''
      this.p5 = ''
      this.p6 = ''
      this.comments = ''
      this.$v.$reset()
    }
  },
}
